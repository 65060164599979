@import 'styles/abstracts/variables';

.print-container {

    .company {
        display: flex;
        flex-basis: 100%;

        .company-logo {
            display: flex;
            flex-basis: 50%;
            justify-content: center;

            img {
                display: block;
                max-width: 500px;
                max-height: 200px;
                width: auto;
                height: auto;
            }

            .company-name {
                display: flex;
                align-items: center;
                font-size: 2em;
                max-width: 100mm;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .company-details {
            display: flex;
            flex-basis: 50%;
            justify-content: right;

            p,
            h2 {
                margin: 0;
                text-align: right;
            }

            .tax {
                margin-top: 24px;
            }
        }
    }

    .items-grid {
        // display: flex;
        // flex-basis: 100%;
    }

    // bill to
    .invoice {
        display: flex;
        flex-basis: 100%;

        .bill-to {
            display: flex;
            flex: 3;
            justify-content: left;
            flex-direction: column;

            p {
                margin: 0;
            }
        }

        .invoice-info {
            display: flex;
            flex: 2;
            justify-content: right;
            text-align: right;

            .labels {
                p {
                    margin: 0;
                }
            }

            .values {
                margin-left: 24px;

                p {
                    margin: 0;
                }

            }
        }
    }

    .invoice-sum {
        display: flex;
        flex-basis: 100%;
        justify-content: right;

        .sum {
            flex-basis: 50%;
            text-align: right;

            p {
                margin: 4px;
            }
        }
    }
}

.divider-with-margin {
    margin: 20px 0;
}

.totals-row {
    display: flex;

    div {
        flex: 1;
    }
}
