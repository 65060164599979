@import 'styles/abstracts/variables';

.btn.disabled,
.disabled.btn-large,
.disabled.btn-small,
.btn-floating.disabled,
.btn-large.disabled,
.btn-small.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-flat:disabled,
.btn[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-floating[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-flat[disabled] {
    background-color: $grey-button !important;
    color: $light-grey !important;
}

input.invalid:not([type]),
input.invalid:not([type]):focus,
input.invalid[type=text]:not(.browser-default),
input.invalid[type=text]:not(.browser-default):focus,
input.invalid[type=password]:not(.browser-default),
input.invalid[type=password]:not(.browser-default):focus,
input.invalid[type=email]:not(.browser-default),
input.invalid[type=email]:not(.browser-default):focus,
input.invalid[type=url]:not(.browser-default),
input.invalid[type=url]:not(.browser-default):focus,
input.invalid[type=time]:not(.browser-default),
input.invalid[type=time]:not(.browser-default):focus,
input.invalid[type=date]:not(.browser-default),
input.invalid[type=date]:not(.browser-default):focus,
input.invalid[type=datetime]:not(.browser-default),
input.invalid[type=datetime]:not(.browser-default):focus,
input.invalid[type=datetime-local]:not(.browser-default),
input.invalid[type=datetime-local]:not(.browser-default):focus,
input.invalid[type=tel]:not(.browser-default),
input.invalid[type=tel]:not(.browser-default):focus,
input.invalid[type=number]:not(.browser-default),
input.invalid[type=number]:not(.browser-default):focus,
input.invalid[type=search]:not(.browser-default),
input.invalid[type=search]:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus,
.select-wrapper.invalid>input.select-dropdown,
.select-wrapper.invalid>input.select-dropdown:focus {
    border-bottom: 1px solid #9e9e9e;
    box-shadow: none;
}

input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type=text]:not(.browser-default),
input.valid[type=text]:not(.browser-default):focus,
input.valid[type=password]:not(.browser-default),
input.valid[type=password]:not(.browser-default):focus,
input.valid[type=email]:not(.browser-default),
input.valid[type=email]:not(.browser-default):focus,
input.valid[type=url]:not(.browser-default),
input.valid[type=url]:not(.browser-default):focus,
input.valid[type=time]:not(.browser-default),
input.valid[type=time]:not(.browser-default):focus,
input.valid[type=date]:not(.browser-default),
input.valid[type=date]:not(.browser-default):focus,
input.valid[type=datetime]:not(.browser-default),
input.valid[type=datetime]:not(.browser-default):focus,
input.valid[type=datetime-local]:not(.browser-default),
input.valid[type=datetime-local]:not(.browser-default):focus,
input.valid[type=tel]:not(.browser-default),
input.valid[type=tel]:not(.browser-default):focus,
input.valid[type=number]:not(.browser-default),
input.valid[type=number]:not(.browser-default):focus,
input.valid[type=search]:not(.browser-default),
input.valid[type=search]:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid>input.select-dropdown {
    border-bottom: 1px solid #9e9e9e;
    box-shadow: none;
}

input.valid.ng-touched.ng-dirty:not([type]),
input.valid.ng-touched.ng-dirty:not(.browser-default) {
    border-bottom: 1px solid #4CAF50;
    box-shadow: 0 1px 0 0 #4CAF50;
}

input.invalid.ng-touched.ng-dirty:not([type]),
input.invalid.ng-touched.ng-dirty:not(.browser-default) {
    border-bottom: 1px solid #F44336;
    box-shadow: 0 1px 0 0 #F44336;
}

.btn-floating,
.btn-floating:focus,
.btn-floating:hover,
.btn-floating:active {
    background-color: $blue;
}

.btn,
.btn-large,
.btn-small {
    background-color: $blue;
    text-transform: none;
    font-weight: 600;

    &:hover,
    &:focus {
        background-color: $blue;
    }
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
    background-color: $blue;
}

.modal.timepicker-modal {
    width: unset;
}

.vertical[type="checkbox"]+span:not(.lever):before,
.vertical[type="checkbox"]:not(.filled-in)+span:not(.lever):after,
.vertical[type="checkbox"]:checked+span:not(.lever):before {
    left: calc(50% + 9px);
    top: 1.5em;
}

.switch label input[type=checkbox]:checked+.lever {
    background-color: #8fc2c5;
}

.switch label input[type=checkbox]:checked+.lever:after {
    background-color: $blue;
}

.switch-label-top {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    cursor: text;
    transition: transform .2s ease-out, color .2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(-14px) scale(0.8);
    transform-origin: 0 0;
}

.switch-centered-to-form {
    line-height: 55px;
}

.input-field .helper-text {
    color: #b4b4b4;
}

.switch .helper-text {
    min-height: 18px;
    display: block;
    font-size: 12px;    
    padding-top: 5px;
    color: #b4b4b4;
}

.input-field .helper-text.error-helper-text {
    color: red;
}

[type="checkbox"]:not(:checked):disabled+span:not(.lever):before {
    background-color: transparent;
    border-color: #eee!important;
    cursor: default;
    border: 2px solid #5a5a5a;
}

[type="checkbox"] + span.label-first:not(.lever) {
    padding-left: 0;
    padding-right: 35px;
}

[type="checkbox"] + span.label-first:not(.lever):before, [type="checkbox"]:not(.filled-in) + span.label-first:not(.lever):after {
    left: unset;
    right: 0;
}

[type="checkbox"]:checked + span.label-first:not(.lever):before {
    right: 15px;
}
// .input-field .ng-select-container, .materialize-ngselect .ng-select-container {
//     height: calc(30px + 1rem);
//     padding: 0.5rem;
//     margin: 0 0 8px 0;
// }