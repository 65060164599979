// If it’s a hack, it goes in shame.css.
// Document all hacks fully:
//  What part of the codebase does it relate to?
//  Why was this needed?
//  How does this fix it?
//  How might you fix it properly, given more time?
// Do not blame the developer; if they explained why they had to do it then their reasons are probably (hopefully) valid.
// Try and clean shame.css up when you have some down time.
//  Even better, get a tech-debt story in which you can dedicate actual sprint time to it.




.svg-fix {
    margin: 3px;

    svg {
        fill: $medium-grey;
    }
}