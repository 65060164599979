.noUi-horizontal .noUi-handle {
    border: none;
    box-shadow: none;
    background: $blue;
    border-radius: 100%;
    height: 1.2em;
    width: 1.2em;
    top: -.6em;

    &:before,
    &:after {
        content: none;
    }
}

.noUi-target {
    height: 1px;
    border: none;
    box-shadow: none;
    border-top: 1px solid $medium-grey;
}

.noUi-connects {
    height: 1.5px;
    margin-top: -1px;
    border-radius: 0;
}

.noUi-connect {
    background: $blue;
}

.noUi-pips {
    .noUi-value {
        font-size: 12px;
    }

    .noUi-marker-large {
        display: none;
    }
}