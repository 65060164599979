pudo-partners, pudo-points{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #fafafa;

    *{box-sizing: border-box;}
}


.lm-pudo-header{
    height: 60px;
    background: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    .lm-pudo-header-section{
        display: flex;
        flex: 1;
        align-items: center;
    }

    .lm-pudo-title{
        font-weight: 500;
        margin: 0;
        font-size: 1.7rem;
    }

    .lm-pudo-search{
        display: block;
        height: 60px;
        width: 70%;

        .grid{
            margin: 0;

            &>div{
                height: 60px;
            }
        }
        .p-inputtext{
            margin: 0;
            // height: 20px;
            // border-radius: 10px;
        }
        .p-autocomplete-panel{
            left: 0 !important;
            top: 40px;
        }
    }

    .lm-pudo-header-dropdown{
        display: inline-flex;
        padding-top: 8px;

        .lm-dropdown-control{
            margin-bottom: 0;
        }
        .p-float-label{
            margin-left: 0;
        }
    }
    
}

.lm-pudo-swagger-btn{
    .lm-button{
        padding: 0.34rem 0.7rem 0.34rem 0.4rem;
        margin-left: 1rem;
        background: #00aeba;
        color: #fff;
        width: 25px;

        .p-button-icon{
            font-size: 1rem;
            margin-right: 0.4rem;
            color: #fff;
        }

        .p-button-label{
            font-size: .88rem;
            font-weight: 500;
        }

        &:hover{
            padding-left: .6rem;
            width: 61px;
            opacity: 1;
            background: #00aeba;
            .p-button-icon{
                margin-right: 0.3rem;
            }
        }
    }
    &.lm-pudo-app-swagger-btn{
        .lm-button{
            margin-left: 0;
        }
    }
}

.lm-pudo-sidebar{
    top: 124px;
    left: 0;
    width: 140px;
    bottom: 0;
    position: absolute;
    background: #ececec;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;

    .p-button{
        width: 100%;
        background-color:transparent;
        color: #495057;
        
        .p-button-label{
            font-size: 1.1rem;
            font-weight: normal;
        }
        .p-button-icon{
            font-size: 1.1rem;
            color: #777;
        }

        &:hover{
            background-color: transparent;
            color: #00aeba;
        }
        &.active{
            color:#00aeba;
        }
    }
}
.lm-pudo-content{
    top: 124px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;

    .lm-pudo-settings-wrapper{
        position: relative;
        height: 100%;


        .lm-pudo-settings-header{
            height: 30px;
            position: absolute;
            top: 0;
            bottom: auto;
            left: 0;
            right: 0;

            .lm-pudo-settings-title{
                font-size: 1.4rem;
            }
        }

        .lm-pudo-settings-content{
            position: absolute;
            top: 60px;
            bottom: 60px;
            left: 0;
            right: 0;
            overflow: auto;
        }

        .lm-pudo-settings-footer{
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            height: 60px;

            .lm-pudo-settings-title{
                font-size: 1.4rem;
            }
        }
    }
        


    &.lm-pudo-sidebar-layout{
        left: 150px;
        top: 124px;
    }

    .lm-pudo-widget-area{
        height: 40%;
        gap: 1.5rem;
        display: flex;
        padding: 1.5rem 1.5rem 1rem 1.5rem;

        .lm-pudo-widget {
            padding: 1rem 1.5rem;
            border-radius: 15px !important;
            display: flex;
            flex-direction: column;

            .lm-pudo-widget-header{
                display: flex;
                justify-content: space-between;
                height: 20px;

                .lm-pudo-widget-title{
                    font-size: 1.3rem;
                    line-height: 100%;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }
            .lm-pudo-widget-chart{
                flex-grow: 1;

                .lm-pudo-count-area{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #00aeba;
                    font-size: 3rem;
                    font-weight: bold;
                    height: 100%;
                }

                .lm-pudo-widget-filter{
                    text-align: center;
                    background-color: #eee;
                    border-radius: 10px;
                    font-size: .9rem;

                    &.lm-square{
                        width: 70%;
                        margin: 0 auto;
                        .lm-square-content{
                            flex-direction: column;
                        }
                    }

                    .lm-icon{
                        padding-top: 5px !important;
                        &:before{
                            color: #666;
                            font-size: 1.6rem;
                        }
                    }

                    &._active{
                        color: #fff;
                        background-color: #00aeba;

                        .lm-icon{
                            &:before{
                                color: #fff;
                            }
                        }
                    }

                    
                }

                .icon-tick-fill, .icon-box-approved, .icon-calendar, .icon-pudo-store{
                    color: #00aeba;
                }
                .icon-heart{
                    color: #ad1919;
                }
            }
            
        }

        .lm-pudo-widget-actions{

            .lm-pudo-widget-action{
                color: #ccc;
                margin-left: 10px;
                font-size: 1.3rem;
                cursor: pointer;
                margin-right: 0;
                padding: 0;

                &.active{
                    color: #00aeba;
                    fill: #00aeba;
                }
            }
        }
    }



    .lm-pudo-table-area{
        height: 60%;
        
        .surface-card{
            border-radius: 15px;
        }
        .lm-pudo-table{
            // height: 100%; 

            .p-datatable-wrapper{
                // height: 400px;
            }
            .p-datatable-thead th{
                color: #999;
                font-size: 0.88rem;
            }
            .lm-pudo-inline-icon-cell{
                .lm-icon:before{
                    font-size: 1rem !important;
                }
            }
            .lm-pudo-stage-cell{
                font-weight: bold;
                border-radius: 16px;
                padding: 7px 20px;
            }
            .lm-delivered-cell{
                color:#00954d;
                background-color: #93ffcb;
            }
            .lm-cancelled-pickup-cell{
                color:#ad1919;
                background-color: #ffe2e4;
            }
            .lm-cancelled-delivery-cell{
                color:#f66868;
                background-color: #e8969b;
            }
            .lm-expired-cell{
                color:#810391;
                background-color: #b73cc8;
            }
            .lm-awaiting-return-cell, .lm-returned-cell{
                color:#e0a953;
                background-color: #fff2d8;
            }
            .lm-awaiting-pickup-cell{
                color:#536ba4;
                background-color: #c1d1f7;
            }
            .lm-awaiting-delivery-cell{
                color:#032c8d;
                background-color: #6895ff;
            }
            .lm-pudo-table-40-cell{
                width: 40%;
            }
            .lm-pudo-table-30-cell{
                width: 30%;
            }
            .lm-pudo-table-20-cell{
                width: 20%;
            }
            .lm-pudo-table-brand-cell{
                width: 20%;
            }
            .lm-pudo-table-address-cell{
                width: 25%;
            }
            .lm-pudo-table-points-cell{
                text-align: center;
            }
            .lm-pudo-table-shipments-cell{
                text-align: center;
            }
            
        }
    }
}

.lm-pudo-modal{}



