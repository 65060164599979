.lm-border-box{
  box-sizing: border-box;
}
.lm-rel {
  position: relative !important;
}
.lm-absolute{
  position: absolute !important;
}
.lm-abs-top{
  position: absolute !important;
  top: 0 !important
}
.lm-abs-bottom{
  position: absolute !important;
  bottom: 0 !important
}
.lm-abs-left{
  position: absolute !important;
  left: 0 !important
}
.lm-abs-right{
  position: absolute !important;
  right: 0 !important
}
.lm-fixed {
  position: fixed !important;
}
.lm-width {
  width: 100% !important;
}
.lm-no-width{
  width: 0 !important;
}
.lm-height {
  height: 100% !important;
}
.lm-half {
  height: 50% !important;
}
.lm-no-height{
  height: 0 !important;
}
.lm-two {
  width: 50%;
}
.lm-three {
  width: 33.3%;
}
.lm-four {
  width: 25%;
}
.lm-five {
  width: 20%;
}
.lm-six {
  width: 16.7%;
}
.lm-f-two {
  width: 50%;
  float: left;
}
.lm-three {
  width: 33.3%;
  float: left;
  &.lm-two-resp {
    @media (max-width: 1279px) {
      width: 50%;
    }
  }
}
.lm-four {
  width: 25%;
  float: left;
  &.lm-two-resp {
    @media (max-width: 1279px) {
      width: 50%;
    }
  }
}
.lm-five {
  width: 20%;
  float: left;
  &.lm-two-resp {
    @media (max-width: 1279px) {
      width: 50%;
    }
  }
}
.lm-six {
  width: 16.7%;
  float: left;
  &.lm-two-resp {
    @media (max-width: 1279px) {
      width: 50%;
    }
  }
}
.lm-two-three {
  width: 66%;
  float: left;
  &.lm-two-resp {
    @media (max-width: 1279px) {
      width: 50%;
    }
  }
}
.lm-four-five {
  width: 75%;
  float: left;
  &.lm-two-resp {
    @media (max-width: 1279px) {
      width: 50%;
    }
  }
}
.lm-flex-1{
  flex:1
}
.lm-flex-2{
  flex:2
}
.lm-flex-3{
  flex:3
}
.lm-flex-4{
  flex:4
}
.lm-flex-5{
  flex:5
}
.lm-flex-6{
  flex:6
}
.lm-left {
  float: left !important;
}
.lm-right {
  float: right !important;
}
.lm-hidden {
  overflow: hidden !important;
}
.lm-auto {
  overflow: auto !important;
}
.lm-round {
  border-radius: 100% !important;
}
.lm-border-rad-15 {
  border-radius: 15px !important;
}
.lm-border-rad-20 {
  border-radius: 20px !important;
}
.lm-border-rad-25 {
  border-radius: 25px !important;
}
.lm-border-rad-30 {
  border-radius: 30px !important;
}
.lm-border-rad-35 {
  border-radius: 35px !important;
}
.lm-pd-0_5rem{
  padding: 1rem !important;
}
.lm-pd-1rem{
  padding: 1rem !important;
}
.lm-pd-1_5rem{
  padding: 1rem !important;
}
.lm-pd-2rem{
  padding: 1rem !important;
}
.lm-mrg-0_5rem{
  margin: 0.5rem !important;
}
.lm-mrg-1rem{
  margin: 1rem !important;
}
.lm-mrg-1_5rem{
  margin: 1.5rem !important;
}
.lm-mrg-2rem{
  margin: 2rem !important;
}
.lm-pd0 {
  padding: 0 !important;
}
.lm-pdl0 {
  padding-left: 0 !important;
}
.lm-pdr0 {
  padding-right: 0 !important;
}
.lm-pdt0 {
  padding-top: 0 !important;
}
.lm-pdb0 {
  padding-bottom: 0 !important;
}
.lm-pd5 {
  padding: 5px !important;
}
.lm-pd10 {
  padding: 10px !important;
}
.lm-pd20 {
  padding: 20px !important;
}
.lm-pd30 {
  padding: 30px !important;
}
.lm-pd40 {
  padding: 40px !important;
}
.lm-pd50 {
  padding: 50px !important;
}
.lm-pdr5 {
  padding-right: 5px !important;
}
.lm-pdr10 {
  padding-right: 10px !important;
}
.lm-pdr20 {
  padding-right: 20px !important;
}
.lm-pdr30 {
  padding-right: 30px !important;
}
.lm-pdr40 {
  padding-right: 40px !important;
}
.lm-pdl5 {
  padding-left: 5px !important;
}
.lm-pdl10 {
  padding-left: 10px !important;
}
.lm-pdl20 {
  padding-left: 20px !important;
}
.lm-pdl30 {
  padding-left: 30px !important;
}
.lm-pdl40 {
  padding-left: 40px !important;
}
.lm-pdb10 {
  padding-bottom: 10px !important;
}
.lm-pdb20 {
  padding-bottom: 20px !important;
}
.lm-pdb30 {
  padding-bottom: 30px !important;
}
.lm-pdb40 {
  padding-bottom: 40px !important;
}
.lm-pdb50 {
  padding-bottom: 50px !important;
}
.lm-pdt10 {
  padding-top: 10px !important;
}
.lm-pdt15 {
  padding-top: 15px !important;
}
.lm-pdt20 {
  padding-top: 20px !important;
}
.lm-pdt30 {
  padding-top: 30px !important;
}
.lm-pdt40 {
  padding-top: 40px !important;
}
.lm-pdt50 {
  padding-top: 50px !important;
}
.lm-mrg0{
  margin: 0 !important;
}
.lm-mrgl10 {
  margin-left: 10px !important;
}
.lm-mrgl20 {
  margin-left: 20px !important;
}
.lm-mrgl30 {
  margin-left: 30px !important;
}
.lm-mrgl40 {
  margin-left: 40px !important;
}
.lm-mrgr0 {
  margin-right: 0 !important;
}
.lm-mrgr5 {
  margin-right: 5px !important;
}
.lm-mrgr10 {
  margin-right: 10px !important;
}
.lm-mrgr20 {
  margin-right: 20px !important;
}
.lm-mrgr30 {
  margin-right: 30px !important;
}
.lm-mrgr40 {
  margin-right: 40px !important;
}
.lm-mrgr50 {
  margin-right: 50px !important;
}
.lm-mrgb10 {
  margin-bottom: 10px !important;
}
.lm-mrgb20 {
  margin-bottom: 20px !important;
}
.lm-mrgb30 {
  margin-bottom: 30px !important;
}
.lm-mrgb40 {
  margin-bottom: 40px !important;
}
.lm-mrgt5 {
  margin-top: 5px !important;
}
.lm-mrgt10 {
  margin-top: 10px !important;
}
.lm-mrgt20 {
  margin-top: 20px !important;
}
.lm-mrgt30 {
  margin-top: 30px !important;
}
.lm-mrgt40 {
  margin-top: 40px !important;
}
.lm-mrgt50 {
  margin-top: 50px !important;
}
.lm-tbl-auto {
  table-layout: auto !important;
}
.lm-tbl-zebra tr:nth-child(odd) {
  background-color: #f5f7f7 !important;
}
.lm-trim {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lm-section {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.lm-section-float {
  width: 100%;
  float: left;
}
.lm-section-flex {
  display: flex;
  margin-bottom: 20px;
}
@mixin lm-motion-fast-mixin{
  -webkit-transition: all 200ms ease-out !important;
  -moz-transition: all 200ms ease-out !important;
  -o-transition: all 200ms ease-out !important;
  transition: all 200ms ease-out !important;
}
@mixin lm-motion-mixin{
  -webkit-transition: all 500ms ease-out !important;
  -moz-transition: all 500ms ease-out !important;
  -o-transition: all 500ms ease-out !important;
  transition: all 500ms ease-out !important;
}
@mixin lm-motion-slow-mixin{
  -webkit-transition: all 1800ms ease-out !important;
  -moz-transition: all 1800ms ease-out !important;
  -o-transition: all 1800ms ease-out !important;
  transition: all 1800ms ease-out !important;
}
.lm-motion-fast {
  @include lm-motion-fast-mixin;
}
.lm-motion {
  @include lm-motion-mixin;
}
.lm-motion-slow {
  @include lm-motion-slow-mixin;
}
.lm-dialog-motion-fast {
  .p-dialog{
    @include lm-motion-fast-mixin;
  }
}
.lm-dialog-motion {
  .p-dialog{
    @include lm-motion-mixin;
  }
}
.lm-dialog-motion-slow {
  .p-dialog{
    @include lm-motion-slow-mixin;
  }
}
.lm-motion-delay-fast{
  transition-delay: 200ms;
}
.lm-motion-delay{
  transition-delay: 500ms;
}
.lm-motion-delay-slow{
  transition-delay: 1800ms;
}
@mixin lm-valign-mixin {
  position: absolute !important;
  top: 50% !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.lm-valign {
  @include lm-valign-mixin;
}
.lm-valign-flex {
  display: flex !important;
  align-items: center !important;
}
.lm-coloumn-flex {
  display: flex;
  align-items: stretch;
  height: 100%;
  flex-flow: column nowrap;
}
.lm-row-flex {
    display: flex;
    align-items: stretch;
    width: 100%;
    flex-flow: row wrap;
  }
.lm-flex {
  display: flex !important;
}
.lm-ai-stretch-flex{
  display: flex !important;
  align-items: stretch !important;
}
.lm-ai-center-flex {
  display: flex !important;
  align-items: center;
}
.lm-jc-space-around-flex {
  display: flex !important;
  justify-content: space-around !important;
}
.lm-jc-space-between-flex {
  display: flex !important;
  justify-content: space-between !important;
}
.lm-jc-end-flex {
  display: flex !important;
  justify-content: flex-end !important;
}
.lm-jc-center-flex {
  display: flex !important;
  justify-content: center !important;
}
.lm-ai-jc-center-flex{
  display: flex !important;
  align-items: center;
  justify-content: center !important;
}
.lm-stretch-flex{
  display: flex !important;
  align-items: stretch !important;
}
.lm-ac-space-around-flex{
  display: flex !important;
  align-content: space-around !important;
}
.lm-select-grid {
  display: grid;
  grid-gap: 10px;
}
.lm-select-grid-checker {
  grid-gap: 10px;
  grid-template-columns: 12px 86px 1fr !important;
}
.lm-select-grid-checker-col-2 {
  grid-gap: 10px;
  grid-template-columns: 20px 1fr !important;
}

.lm-pic{
  overflow: hidden;
  position: relative;
}
.lm-img, .lm-pic img{
  width: 100% !important; 
  vertical-align: top;
}
.lm-bg-img-auto-fit{
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  background: no-repeat center/cover;
}

.lm-square {
  width: 100%;
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .lm-square-content{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin lm-align-mixin {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.lm-align {
  @include lm-align-mixin;
}
.lm-font-weight-normal{
  font-weight: normal !important;
}
.lm-font-weight-bold{
  font-weight: bold !important;
}
.lm-txt-color-mily{
  color: #00aeba !important;
}
.lm-bg-color-white{
  background-color: #fff !important;
}
.lm-bg-color-mily{
  background-color: #00aeba !important;
}
.lm-bg-transparent {
  background-color: transparent !important;
}
.lm-txt-dark-color-mily{
  color: #037b83 !important;
}
.lm-bg-dark-color-mily{
  background-color: #037b83 !important;
}
.lm-txt-color-body {
  color: #495057 !important;
}
.lm-txt-color-red {
  color: #f00000 !important;
}
.lm-txt-color-green {
  color: #00ba61 !important;
}
.lm-txt-color-light-grey {
  color: #ccc !important;
};
.lm-txt-color-grey {
  color: #999 !important;
};
.lm-txt-left {
  text-align: left !important;
}
.lm-txt-right {
  text-align: right !important;
}
.lm-txt-center {
  text-align: center !important;
}
.lm-font-100{
  font-weight: 100 !important;
}
.lm-font-300{
  font-weight: 300 !important;
}
.lm-font-500{
  font-weight: 500 !important;
}
.lm-font-700{
  font-weight: 700 !important;
}
.lm-opac {
  opacity: 0.4 !important;
}
.lm-transparent {
  opacity: 0;
}
.lm-colorize{
  *{
    color: inherit !important;
  }
}
@mixin lm-shadow-mixin {
  -webkit-box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.6);
}
.lm-shadow {
  @include lm-shadow-mixin;
}
.lm-press {
  cursor: pointer !important;
}
.lm-nowrap {
  white-space: nowrap !important;
}
.lm-txt-wrap {
  white-space: normal !important;
}
.lm-gpu-accelerate {
  transform: translateZ(0);
}
@mixin lm-overlay-mixin {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: inherit;
  z-index: 9999;
}
.lm-overlay {
  @include lm-overlay-mixin;
}
.lm-overlay-white {
  @include lm-overlay-mixin;
  background-color: rgba(255,255,255,.4);
}
.lm-overlay-black {
  @include lm-overlay-mixin;
  background-color: rgba(0,0,0,.4);
}
.lm-block {
  display: block !important;
}
.lm-readonly-input,
.lm-readonly-input [type='text'] {
  color: #495057 !important;
  opacity: 1 !important;
}
.lm-input-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lm-form-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.lm-display-none {
  display: none !important;
}
.lm-invisible{
  visibility: hidden;
}
.lm-b-w{
  filter: grayscale(1);
}
.lm-user-select-none{
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}
.lm-swipe-vertical-img{
  background: url(/assets/lastmilyAssets/last-mily-swipe-gesture.png) center / 100% no-repeat;
}
.lm-motion-delay-1s{
  animation-delay: 1s;
}
.lm-motion-delay-2s{
  animation-delay: 2s;
}
.lm-motion-delay-3s{
  animation-delay: 3s;
}
.lm-motion-delay-4s{
  animation-delay: 4s;
}
.lm-motion-delay-5s{
  animation-delay: 5s;
}

.lm-swipe-vertical-motion{
  animation: swipeVertical 4s ease 0s 1 normal forwards;
}

@keyframes swipeVertical {
	0%,
  60%,
	100% {
		transform: translateY(0);
	}

	10%,
	30%,
	50%
	// 70%
   {
		transform: translateY(-12px);
	}

	20%,
	40%
	// 60% 
  {
		transform: translateY(12px);
	}

	// 80% {
	// 	transform: translateY(7px);
	// }

	// 90% {
	// 	transform: translateY(-7px);
	// }
}

.lm-blink{
  animation: blink 2s ease 0s 1 normal forwards;
}
@keyframes blink {
	0% {opacity: 1;}
	50% {opacity: 0.2;}
	100% {opacity: 1;}
}

.lm-animation-fade-in{
  animation: fadein 3s ease 0s 1 normal forwards;
}
@keyframes fadein {
	0% {opacity: 0;}
	100% {opacity: 1 !important;}
}


@keyframes fadeout {
	0% {opacity: 1;}
	100% {opacity: 0;}
}

.lm-animation-fade-out{
  animation: fadeout 3s ease 0s 1 normal forwards;
}

.lm-animation-slide-in-right{
  animation: slideInRight 1s ease 0s 1 normal forwards;;
}


@keyframes slideInRight {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.lm-swirl-in-forward{
  animation: swirlInForward 1s ease 0s 1 normal forwards;
}

.lm-motion-delay-1{
  animation-delay: 1s;
}
.lm-motion-delay-2{
  animation-delay: 2s;
}
.lm-motion-delay-3{
  animation-delay: 3s;
}
.lm-motion-delay-4{
  animation-delay: 4s;
}
.lm-motion-delay-5{
  animation-delay: 5s;
}

@keyframes swirlInForward {
	0% {
		opacity: 0;
		transform: rotate(-540deg) scale(0);
	}

	100% {
		opacity: 1;
		transform: rotate(0) scale(1);
	}
}

.lm-translator {}