.progress-bar-container {
    position: relative;
    width: 10vw;
    border: 1px solid $dark-grey;
    height: 12px;
    border-radius: 5px;
    margin: 0 5px;
}

.progress-bar {
    position: relative;
    box-sizing: content-box;
    height: 100%;
    width: 0;
    max-width: 100%;
    background: $dark-grey;
    border-radius: 4px;
    z-index: 1;
    -webkit-animation: animate-width;
    -moz-animation: animate-width;
    animation: animate-width;
    animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
    -webkit-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
    -moz-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;

    &.progress-bar-secondary {
        position: absolute;
        top: 0;
        background: #66666652;
        background: #cdcdcd;
        z-index: 0;
    }
}