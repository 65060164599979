@import "styles/abstracts/variables";

.collaborator-driver-box {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: rgba(255, 255, 255, 1);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8em;
    width: 12vw;
    overflow: hidden;
    user-select: none;
    transition: opacity 0.2s, left 0.2s, top 0.2s;

    .company-image-container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 64px;

        .company-image {
            width: 100%;
            height: 64px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .driver-info-container {
        display: flex;

        .driver-image {
            width: 50px;
            height: 50px;
            background-size: cover;
            border-radius: 50%;
            margin-right: 8px;
        }

        .driver-info {
            display: flex;
            flex-direction: column;

            .driver-name {
                font-size: 16px;
            }

            .driver-vehicle {
                width: 40px;
                height: 40px;
                margin-right: 5px;
                font-size: 0; // sanitization message hide

                svg {
                    width: 30px;

                    path {
                        fill: #999;
                    }
                }
            }
        }
    }

    .driver-arrival {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .label {
            text-align: center;
            font-size: 14px;
        }
        .arrival {
            font-size: 16px;
            font-weight: bold;
        }
    }

    .food-pickup-label {
        text-align: center;
        font-size: 11px;
        font-weight: bold;
        white-space: pre-line;
    }

    .food-company-image-container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 32px;
        margin-bottom: 4px;

        .company-image {
            width: 100%;
            height: 32px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

// food mode box sizing/spacing
.smaller-box {
    width: 7.25vw;
    padding: 10px 2px 10px 2px;
}

.collaborator-driver-box:active {
    // opacity: 0;
    display: none;
}
