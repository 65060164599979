@import 'styles/abstracts/variables';

.white-bar-back-button {
    background-color: unset;
    padding-left: 20px;
    margin-top: 4px;
    transition: opacity .2s;
    max-width: 3vw;
}
.white-bar-back-button:hover {
    opacity: 0.7;
    cursor: pointer;
}

#white-bar {
    width: 100vw;
    height: 65px;
    z-index: 1;
    position: relative;
    background-color: white;
    box-shadow: 0px 1px 5px 0px $medium-grey;
    display: flex;
    align-items: center;
}

.white-bar-new-button {
    background-color: $blue;
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    .white-bar-new-icon {
        display: contents;
    }

    .white-bar-new-text {
        color: white;
        padding-left: 10px;
        font-size: 1.2em;
    }
}

.white-bar-tabs {
    flex: 1;
    margin: auto;
}

.white-bar-sections {
    display: flex;
    height: 100%;
    align-items: center;

    &.first-section {
        flex: 1;
        border-right: 1px solid #999;
    }

    &.middle-section.white-bar-sections {
        flex: 4;
        justify-content: space-around;
    }

    &.last-section.white-bar-sections {
        flex: 1;
        border-left: 1px solid #999;
        justify-content: center;
    }
}

.nav-link {
    .fill-evenodd {
        fill: $light-grey;
    }
}

nav ul {
    margin-top: 5px;
}

.nav-extended {
    background-color: $nav-black;
    z-index: 2;
    box-shadow: none;
}

nav .brand-logo {
    margin-right: 20px;
    font-size: 2.5em;

    &.svg-logo {
        width: 75px;
        margin: 0;
        margin-top: -8px;
    }
}

@media only screen and (max-width: 992px) {
    nav .brand-logo {
        left: unset;
        transform: unset;
    }
}

.left-nav {
    height: 65px;
}

.brand-logo small {
    display: none;
}
