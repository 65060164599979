@import 'styles/abstracts/variables';

.side-modal {
    box-sizing: content-box;
    border-left: 1px solid $light-grey;
    position: fixed;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 109px);
    width: 50vw;
    top: 64px;
    right: 0;
    transition: transform 1s;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);

    &.open {
        transform: translateX(0);
        -webkit-transform: translateX(0);
    }

    &.closed {
        transform: translateX(100%);
        -webkit-transform: translateX(100%);

        &.large-modal {
            right: -70vw;
        }
    }

    &.one-bar-height {
        height: calc(100vh - 64px);

        .side-modal-body {
            height: calc(100vh - 167px);
            max-height: calc(100vh - 167px);
        }
    }

    &.large-modal {
        right: -70vw;
        width: 70vw;

        &.open {
            right: 0;
        }
    }

    &.expanded.open {
        .side-modal-body.open {
            .container {
                width: 90%;
            }
        }
    }

    &.new-look {
        border-radius: 15px;
        background: white;
        margin: 10px;
        height: calc(100vh - 129px);
        top: 50%;
        transform: translate(0%, -50%) scale(0.8);
        -webkit-transform: translate(0%, -50%) scale(0.8);
        opacity: 0;
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;

        &.open {
            transform: translate(0, -50%) scale(1);
            -webkit-transform: translate(0, -50%) scale(1);
            opacity: 1;
        }

        &.closed {
            transform: translate(0%, -50%) scale(0.8);
            -webkit-transform: translate(0%, -50%) scale(0.8);
            opacity: 0;
        }

        .ag-theme-material .ag-row-selected:before {
            background-color: unset !important;
        }

        .side-modal-header {
            background: transparent;
            height: 60px;
            padding: 0 15px;
            border-bottom: solid 1px #f0f0f0;

            .modal-title {
                color: black;
                font-weight: 500;
            }

            .close-button {
                width: 20px;
                height: 20px;
                border-radius: 100%;
                background: #eee;
                text-align: center;
            }

            lm-dropdown {
                height: 30px;
            }

            .p-dropdown.p-component.lm-dropdown-control {
                height: 30px;
                margin: 0;
                border-radius: 15px;
                min-width: 10vw;
                margin-right: 1vw;
            }

            .lm-dropdown-control .p-dropdown-label {
                padding: 5px 15px;
                opacity: 1;
                font-size: .9em;
            }

            i.pi:not(.pi-spinner) {
                font-size: 15px;
            }
        }

        .side-modal-body {
            background-color: transparent;
        }

        .ag-header {
            border: none;

            .ag-header-cell-text {
                color: black;
                font-weight: 500;
            }
        }

        .ag-theme-material .ag-row {
            border: none;

            &:nth-child(odd) {
                background-color: #fafafa;
            }
        }

        .ag-theme-material .ag-checkbox-input-wrapper::after {
            border: 2px solid #ccc;
            border-radius: 5px;
        }

        .ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
            border-radius: 1px;
            border-top: 2px solid transparent;
            border-left: 2px solid transparent;
            border-right: 2px solid #26a69a;
            border-bottom: 2px solid #26a69a;
        }
    }

    .side-modal-header {
        background: white;
        display: flex;
        height: 65px;
        padding: 0 50px;
        border-bottom: solid 1px $light-grey;
        align-items: center;
        display: flex;
        justify-content: space-between;
        position: relative;

        .modal-title {
            color: $blue;
            font-size: 1.2em;
        }

        .modal-header-note {
            position: absolute;
            bottom: 0;
            color: #999;
        }
    }

    .side-modal-body {
        flex: 1;
        height: 100%;
        padding: 50px;
        overflow: auto;
        background-color: $off-white;

        &.closed {
            display: none;
        }

        &.modal-with-button {
            margin-bottom: 54px;
        }
    }

    .side-modal-footer {
        width: 100%;
        height: 65px;
        line-height: 65px;
        background-color: $blue;
        color: white;
        font-size: 1.5em;
        text-align: center;
        cursor: pointer;
    }
}

.side-modal-background {
    position: fixed;
    width: 100vw;
    height: 65px;
    background: #0000004a;
    top: 0;
    right: 0;
    z-index: $zIndex1009;
}

.modal-box {
    float: left;
    height: 10vw;
    width: 10vw;
    padding: 1vw;
    margin: 2vw;
    border: 4px solid $light-grey;
    border-radius: 5px;
}

.rules .side-modal {
    height: calc(100vh - 65px) !important;
}

@import 'styles/abstracts/variables';

.custom-modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 15vh;
    height: 70vh;
    width: 40vw;
    left: 30vw;
    z-index: $zIndex1000;
    transition: transform 1s;
    transform: translateY(-400vh);

    &.open {
        transform: translateY(0);
    }

    &.wide-50 {
        width: 50vw;
        left: 25vw;
    }

    &.wide {
        width: 60vw;
        left: 20vw;
    }

    .modal-header {
        background: white;
        display: flex;
        border-bottom: solid 1px $light-grey;
        align-items: center;
        padding: 35px;
        height: 80px;

        .modal-title {
            flex: 1;
            font-size: 1.4em;
            font-weight: 500;
            color: $blue;
        }
    }

    .modal-body {
        flex: 1;
        height: 100%;
        overflow: auto;
        background-color: white;
        padding: 30px;
    }

    .modal-footer {
        width: 100%;
        background-color: $blue;
        color: white;
        font-size: 1.5em;
        text-align: center;
        cursor: pointer;
        display: flex;

        .modal-button {
            height: 65px;
        }
    }

    .modal-load-button {
        text-align: center;
        align-items: center;
        display: flex;
        padding: 0 25px;
        border-right: 1px solid;
    }

    .modal-save-button {
        flex: 1;
        background-color: $blue;
        box-shadow: none;
    }
}

.custom-modal-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #0000004a;
    top: 0;
    left: 0;
    z-index: $zIndex999;
}

.custom-project-problem-modal-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #0000004a;
    top: 0;
    left: 0;
    z-index: $zIndex999;
}

.modal.timepicker-modal {
    right: 0;
    left: 0;
}

#driver-form-container .modal.timepicker-modal {
    left: unset;
    right: unset;
}

.driver-modal.expanded {
    #avatar-upload-popup {
        left: 10vw;
    }

    #avatar-upload-popup-bg {
        left: -14vw;
    }
}

.routing-settings-modal-button {
    cursor: pointer;
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 205px;
    left: 25px;
    background-color: white;
    color: #00aeba;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 2s;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;

    &:hover {
        background-color: white;
    }

    &.open {
        transform: translateX(calc(30vw - 15px));
        -webkit-transform: translateX(calc(30vw - 15px));
    }
}

.halo-hover:hover {
    box-shadow: 0px 0px 0px 10px #ffffff77;
}