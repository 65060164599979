@import "styles/abstracts/variables";

.double-cell {
    &:nth-child(1) {
        margin-top: 5px;
    }

    &:nth-child(2) {
        margin-bottom: 5px;
    }

    height: 18px;
    line-height: 18px;
}

.quad-cell {
    display: flex;

    &:nth-child(1) {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &:nth-child(2) {
        margin-bottom: 5px;
    }

    &:nth-child(3) {
        margin-bottom: 5px;
    }

    &:nth-child(4) {
        margin-bottom: 5px;
    }

    height: 18px;
    line-height: 18px;
}

.payment-methods-cell {
    display: flex;
    height: 100%;
    align-content: center;
    line-height: 18px;

    .column {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.center-content {
    display: flex;
    width: fit-content;
    margin: auto;
}

.standard-width {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.triple-cell {
    height: 15px;
    line-height: 15px;

    .triple-cell-icon {
        font-size: 0.8em;
        width: 25px;
        text-align: center;
        color: $light-grey;
    }
}

.triple-cell-70 {
    line-height: 20px;
}

.lines-in-cell-5 {
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
}

.time-difference {
    font-size: 0.8em;
    line-height: 15px;
    letter-spacing: -1px;
}

.vehicle-cell {
    height: 15px;
    line-height: 15px;
    font-size: 0.8em;
    color: $medium-grey;
}

.vehicle-icon-cell {
    line-height: 27px;
    height: 27px;
    color: transparent !important;
    letter-spacing: -200px;

    svg {
        width: 30px;

        path {
            fill: #999;
        }
    }

    &.dark svg path {
        fill: #666;
    }
}

.arrow-container {
    margin-left: 5px;
    color: $medium-grey;
}

.grey-letters {
    .vehicle-cell {
        color: $light-grey;
    }

    .vehicle-icon-cell {
        color: $light-grey;
    }

    .arrow-container {
        color: $light-grey;
    }
}

.text-right-align {
    text-align: right;
    margin-right: 8px;
}

.right-margin-small {
    margin-right: 2px;
}

.left-margin-small {
    margin-left: 2px;
}

.left-margin-big {
    margin-left: 12px;
}

.ag-row-drag {
    position: absolute;
    right: 0;
}

.ag-theme-material .ag-row-selected {
    background-color: unset;
}

.centered-icon-cell {
    height: 100%;
    display: flex;
}

.ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
    min-width: 100%;
}

.ag-theme-material .ag-cell {
    padding-left: 10px;
    padding-right: 10px;
}

.ag-theme-material .ag-checkbox-input-wrapper input,
.ag-theme-material .ag-checkbox-input-wrapper input {
    pointer-events: auto;
}

.ag-theme-material .ag-checkbox-input-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 1.5px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 3px;
    transition: 0.2s;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
    content: "";
    position: absolute;
    z-index: 0;
    border: 1.5px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 3px;
    transition: 0.2s;
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

::ng-deep .ngx-dropdown .ngx-menu {
    right: 0;
    left: unset;
}

.white-bar-right-button {
    display: flex;
    padding: 0 10px;
    color: $medium-grey;
    font-size: 1.3em;

    svg {
        fill: #999999;
    }

    &.active,
    &:hover {
        color: $blue;

        svg {
            fill: $blue;
        }
    }

    &.absolute-search {
        position: absolute;
        z-index: 1;
        right: 0;
        width: 13vw;
    }
}

.project-problem-modal-search-area {
    position: absolute;
    top: 0;
    right: 0;
    height: 65px;
    display: flex;
    padding: 0 20px;
    margin-right: 70px;

    svg {
        margin-right: 20px;
    }
}

.select-driver {
    width: 10vw;
}

.sequence-cell {
    padding: 0 !important;
    text-align: center;
    color: white;
    writing-mode: vertical-rl;
    line-height: 0.6vw !important;
    font-size: 0.7em;
    width: 0.7vw !important;
}

.dropdown-cell {
    overflow: visible;
}

.dropdown-dots {
    text-align: center;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    transform: scale(1);
    transition: all 0.2s;
}

.dropdown-dots:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.dropdown-dots:active {
    background-color: rgba(0, 0, 0, 0.07);
    transform: scale(0.95);
}

.dropdown-container {
    display: none;
    position: fixed;
    right: 0;
    background: white;
    width: 10vw;
    border: 1px solid $light-grey;
    z-index: 9;

    .dropdown-option {
        padding: 10px;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom: 1px solid $light-grey;
        }

        &:hover {
            color: $blue;
        }
    }
}

.text-center .ag-header-cell-label {
    justify-content: center;
}

.editable-time {
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: $blue;
}

.ag-theme-material {
    font-family: unset;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
    padding: 0 10px;
}

.ag-header .ag-header-cell-text {
    color: $dark-grey;
    font-weight: 400;
}

.ag-header-cell {
    background-color: white;
}

.grey-header-grid .ag-header-cell {
    background-color: $off-white;
}

.table-container-no-scroll {
    height: calc(100vh - 130px);
}

.table-container {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.ag-theme-material .ag-checkbox-input-wrapper:focus-within,
.ag-theme-material .ag-checkbox-input-wrapper:active {
    box-shadow: none;
}

#stop-points-before-optimize,
#stop-points-after-optimize {
    height: calc(100vh - 236px);
}

.header-with-checkbox .ag-header-cell-text {
    padding-left: 40px;
}

#white-bar .materialize-ngselect .ng-select-container {
    border: none;
}

.ag-center-cols-viewport {
    overflow-x: hidden;
}

.ag-theme-material .ag-row {
    cursor: pointer;

    //     &:hover {
    //         background-color: #e0e;
    //     }
}

// .ag-theme-material .ag-row-hover {
//     background-color: #0ee;
// }

.ag-header-cell-label,
.ag-cell-label-container {
    align-items: flex-end;
}

.no-data-row {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
}

.history-last-days-row {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ababab;
    background-color: #efefef;
    cursor: default;
}

.vehicle-type-box {
    &:hover {
        .vehicle-icon {
            fill: $blue;
        }
    }

    &.active {
        .vehicle-icon {
            fill: white;
        }
    }

    .vehicle-icon {
        fill: #666;
    }

    svg {
        margin-top: 20px;
    }
}

.blue-circle-icon {
    background: $blue;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    font-size: 1.2em;
    line-height: 40px;
    text-align: center;
    color: white;
    margin-top: 2px;
}

.grid-wrap-text {
    line-height: 20px;
    white-space: pre-wrap;
    overflow: hidden;
    max-height: 40px;
}

.driver-of-the-day.bold-letters.standard-width {
    width: 100%;
    height: 100%;
    background: $blue;
    color: white;
    text-align: center;
    line-height: 70px;
}

.circle-wrap {
    margin-top: 3px;
    width: 40px;
    height: 40px;
    background: #e6e2e7;
    border-radius: 50%;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
}

.circle-wrap .circle .mask {
    clip: rect(0px, 40px, 40px, 20px);
}

.circle-wrap .circle .mask .fill {
    clip: rect(0px, 20px, 40px, 0px);
    background-color: $blue;
}

.circle-wrap .circle .mask.full,
.circle-wrap .circle .fill {
    animation: fill ease-in-out 3s;
    // transform: rotate(126deg);
}

// @keyframes fill {
//     0% {
//         transform: rotate(0deg);
//     }

//     100% {
//         transform: rotate(180deg);
//     }
// }

.circle-wrap .inside-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    line-height: 30px;
    text-align: center;
    margin-top: 5px;
    margin-left: 5px;
    position: absolute;
    z-index: 100;
    /* font-weight: 700; */
    color: black;
}

// .loader {
//     position: relative;
//     width: 50px;
//     height: 50px;
//     float: left;
//     user-select: none;
//     box-sizing: border-box;
// }

// .loader-bg {
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//     border: 5px solid rgba(255, 255, 255, 0.1);
//     box-sizing: border-box;
// }

// .spiner-holder-one {
//     position: absolute;
//     top: 0;
//     left: 0;
//     overflow: hidden;
//     width: 50%;
//     height: 50%;
//     background: transparent;
//     box-sizing: border-box;
// }

// .spiner-holder-two {
//     position: absolute;
//     top: 0;
//     left: 0;
//     overflow: hidden;
//     width: 100%;
//     height: 100%;
//     background: transparent;
//     box-sizing: border-box;
// }

// .loader-spiner {
//     width: 200%;
//     height: 200%;
//     border-radius: 50%;
//     border: 5px solid $blue;
//     box-sizing: border-box;
// }

// .animate-0-25-a {
//     transform: rotate(90deg);
//     transform-origin: 100% 100%;
// }

// .animate-0-25-b {
//     transform: rotate(-90deg);
//     transform-origin: 100% 100%;
// }

// .animate-25-50-a {
//     transform: rotate(180deg);
//     transform-origin: 100% 100%;
// }

// .animate-25-50-b {
//     transform: rotate(-90deg);
//     transform-origin: 100% 100%;
// }

// .animate-50-75-a {
//     transform: rotate(270deg);
//     transform-origin: 100% 100%;
// }

// .animate-50-75-b {
//     transform: rotate(-90deg);
//     transform-origin: 100% 100%;
// }

// .animate-75-100-a {
//     transform: rotate(0deg);
//     transform-origin: 100% 100%;
// }

// .animate-75-100-b {
//     transform: rotate(-90deg);
//     transform-origin: 100% 100%;
// }

// .text-chart {
//     text-align: center;
//     padding-top: 32%;
//     font-size: 12px;
// }

.grid-inline-icon {
    color: #bbb;
    font-size: 1.5em;
    margin-right: 5px;
    width: 30px;
}

.double-line-grid {
    white-space: break-spaces;
    line-height: 22px;
    max-height: 44px;
}

.grid-new-line-tag {
    position: absolute;
    top: 0;
    right: 5px;
    line-height: 20px;
    font-size: 0.8em;
    color: #999;
    text-transform: lowercase;
}

// Customer Vouchers: Status
.voucher-status-container {
    display: flex;
    align-items: center;
    height: 100%;
    overflow: visible;
}

.voucher-status-container .status-on-going {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    height: 85%;
    width: 100%;
    padding: 4px 12px;
    background-color: #fff2d8;
    color: #ca850e;
    line-height: 1.4;
}

.voucher-status-container .status-completed {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    height: 85%;
    width: 100%;
    padding: 4px 12px;
    background-color: #cdf3f3;
    color: #0c8a87;
    line-height: 1.4;
}

.voucher-status-container .status-canceled {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    height: 85%;
    width: 100%;
    padding: 4px 12px;
    background-color: #ffe2e4;
    color: #be0c1a;
    line-height: 1.4;
}

// Customer Vouchers: Pay On Delivery
.pay-on-delivery-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.pay-on-delivery-container .double-cell {
    margin: 0;
}

.pay-on-delivery-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    height: 80%;
    width: 100%;
    padding: 0px 10px;
    background-color: #fff2d8;
}

.pay-on-delivery-container .pay-on-delivery-label {
    // color: #ca850e;
}

.pay-on-delivery-container .pay-on-delivery-value {
    color: black;
}

// Customer Vouchers: Payment Status
.payment-status-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.payment-status-container .double-cell {
    margin: 0;
}

.payment-status-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    height: 80%;
    width: 100%;
    padding: 0px 10px;
    background-color: #ffe2e4;
}

.payment-status-container .payment-status-label {
    // color: #be0c1a;
}

.payment-status-container .payment-status-value {
    color: black;
}

.status-fix-sizing {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    height: 80%;
    padding: 4px 12px;
    width: 100%;
}

.ng-option {
    .status-unset, .status-on-going, .status-completed {
        background-color: unset
    }
}

.status-unset {
    background-color: #eee;
    color: #333;
}

.status-created {
    background-color: #cdf3f3;
    color: #01adbb;
}

.status-on-going {
    background-color: #fff2d8;
    color: #ca850e;
    line-height: 1.4;
    height: 85%;
}

.status-completed {
    background-color: #cdf3f3;
    color: #0c8a87;
    line-height: 1.4;
    height: 85%;
}

.status-canceled {
    background-color: #ffe2e4;
    color: #be0c1a;
    line-height: 1.4;
    height: 85%;
}

// Customer Invoices: Status
.invoice-status-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.invoice-status-container .status-viewed {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    height: 80%;
    padding: 4px 12px;
    background-color: #fff2d8;
    color: #ca850e;
}

.invoice-status-container .status-sent {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    height: 80%;
    padding: 4px 12px;
    background-color: #cdf3f3;
    color: #01adbb;
}

.invoice-status-container .status-created {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    height: 80%;
    padding: 4px 12px;
    background-color: #cdf3f3;
    color: #01adbb;
}

.invoice-status-container .status-paid {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    height: 80%;
    padding: 4px 12px;
    background-color: #cdf3f3;
    color: #0c8a87;
}

.invoice-status-container .status-overdue {
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    height: 80%;
    padding: 4px 12px;
    background-color: #ffe2e4;
    color: #be0c1a;
}

// Customer Invoices: Action Button
.invoices-actions-button-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.invoices-actions-button,
.invoices-actions-button:active,
.invoices-actions-button:focus {
    display: flex;
    align-items: center;
    text-align: center;
    border: 2px solid #01adbb;
    border-radius: 8px;
    height: 80%;
    padding: 8px 24px;
    background-color: #01adbb;
    color: white;
    cursor: pointer;

    svg {
        margin-right: 5px;
    }
}

.invoices-actions-button:hover {
    display: flex;
    align-items: center;
    text-align: center;
    border: 2px solid #01adbb;
    border-radius: 8px;
    height: 80%;
    padding: 8px 24px;
    background-color: transparent;
    color: #01adbb;
}

// Service Charges: Delete Action
#services-charges-grid .ag-row {
    margin-left: 1vw;
}

#services-charges-grid .ag-row .actions-delete {
    opacity: 0;
    color: #bbbbbb;
}

#services-charges-grid .ag-row:hover {
    .actions-delete {
        opacity: 1;
    }
}

// Weight Charges: Delete Action
#weight-charges-grid .ag-row .actions-delete,
#item-charges-grid .ag-row .actions-delete {
    opacity: 0;
    color: #bbbbbb;
}

#weight-charges-grid .ag-row:hover,
#item-charges-grid .ag-row:hover {
    .actions-delete {
        opacity: 1;
    }
}

// history grid (stop point)
.status-normal {
    color: #6f6f6f;
    font-weight: bold;
    font-size: 14px;
}

.status-red {
    color: #fe1624;
    font-weight: bold;
    font-size: 14px;
}

.status-orange {
    color: #e99400;
    font-weight: bold;
    font-size: 14px;
}

.status-blue {
    color: #25b9c5;
    font-weight: bold;
    font-size: 14px;
}

.logo-col {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    margin-right: 8px;
}

.logo-replacement {
    background: #bbb;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-top: 3px;
    line-height: 40px;
    text-align: center;
    margin-right: 8px;
    color: white;
    font-size: 1.3em;
    text-transform: uppercase;
}

#payments-hover-box {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    color: $dark-grey;
    min-width: 13vw;
    height: 16vh;
    padding: 6px 8px;
    top: 61vh;
    left: 76vw;
    z-index: 1;
    pointer-events: none;
    cursor: default;

    #titles {
        display: flex;
        flex-direction: column;
    }

    #values {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: 8px;

        div {
            text-align: right;
        }
    }
}

.separator-line-full-cell {
    height: 1px;
    width: 100%;
    background-color: rgb(220, 218, 218);
    margin-top: 16px;
}

.separator-line-quarter-cell {
    height: 1px;
    width: 25%;
    background-color: rgb(220, 218, 218);
    margin-top: 16px;
    margin-left: auto;
}

.margin-right-auto {
    margin-right: auto;
}

.grid {
    position: relative;
}

.row-hint {
    color: #999;
    font-size: 0.8em;
    position: absolute;
    bottom: 0;
    left: 40px;
}