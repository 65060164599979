::placeholder {
    color: #9e9e9e;
    opacity: 1; /* Firefox */
}

.form-errors {
    background: #d640401f;
    padding: 0 30px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 10px #00000020;

    ul:not(.browser-default)>li {
        list-style: initial;
        color: $red;
        font-size: .7em;
    }
}

.form-last-element {
    padding-bottom: 50px;
}

.inline-checkbox {
    display: inline;
    margin-left: 15px;

    &.no-margin-left {
        margin-left: 0;
    }
}

.slider-align-fix {
    margin-top: calc(1rem + 8px);
}

.input-with-icon {
    .input-icon {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 22px;
        width: 22px;
        line-height: calc(3rem + 8px);
        height: calc(3rem + 8px);
        color: #666;
    }

    input:not([type]),
    input[type=text]:not(.browser-default),
    input[type=tel]:not(.browser-default),
    input[type=email]:not(.browser-default),
    label,
    textarea.materialize-textarea,
    .ng-select .ng-select-container {
        margin-left: 22px;
        width: calc(100% - 22px);
    }
}

#camera-button-bg svg path {
    stroke: white;
    stroke-width: 100px;
}

label {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ng-dropdown-panel {
    z-index: 20000 !important;
}

.p-dropdown-panel .p-dropdown-items {    
    .p-dropdown-item{
        color: #26a69a;

        &:hover, &.p-highlight{
            color: #495057 !important;
            background: #c8edf0;
        }
    }
}

.lm-dropdown-input{
    background: #f3f3f3;

    .p-inputtext{
        border: none
    }
}

.p-dropdown-panel .p-dropdown-header {
    background: #f0f0f0;

    .p-dropdown-filter {
        color: #666;
    }
}


.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: #666;
    &:focus {
        box-shadow: none;
    }
    &:not(.p-highlight):not(.p-disabled):hover {
        color: #666;
    }
    &.p-highlight {
        color: #666;
        background: unset;
    }
    .p-multiselect-empty-message {
        color: #666;
    }
}

.p-checkbox {
    &:not(.p-checkbox-disabled) .p-checkbox-box{
        &:hover {
            border-color: #00aeba;
        }
        &.p-focus {
            border-color: #00aeba;
        }
        &.p-highlight:hover {
            border-color: #00aeba;
            background: #00aeba;
        }
    }
    .p-checkbox-box {
        color: #666;
        &.p-highlight {
            border-color: #00aeba;
            background: #00aeba;
        }
    }
}
