.lm-widget {
    padding: 1rem 1.5rem;
    border-radius: 15px !important;
    display: flex;
    // flex: 1;
    flex-direction: column;
    // margin: 0.5rem;
    // width: 100%;
    background-color: #fff;
    box-shadow: 2px 0px 4px 2px #00000012;

    &.--chart{
        height: 400px;
    }

    .lm-widget-header{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 20px;
        width: 100%;
        // margin-bottom: 5px;

        .lm-widget-label{max-width: 50%;}

        .lm-widget-title{
            font-size: 1.3rem;
            line-height: 100%;
            font-weight: 500;
            text-transform: capitalize;
        }

        .lm-widget-filters{
            display: flex;
            flex: 1;
            justify-content: flex-end;

            lm-text{
                >.grid{
                    >.col{
                        position: relative;
                        i.pi{
                            position: absolute;
                            z-index: 1;
                            left: 24px;
                            width: 16px;
                            font-size: 1rem;
                        }
                        .p-inputtext{
                            height: 20px;
                            border-radius: 2rem;
                            padding: 0 10px 0 28px
                        }
                    }
                }
            }
            


            .lm-widget-filter{    
                p-inputswitch{
                    transform: scale(.82);
                    height: 25px;

                    .p-inputswitch{
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
                .padding-input{
                    padding: 0 !important;
                }
            }
        }

        .lm-widget-tabs{
            margin-right: -0.5rem;
            margin-left: 1.5rem;
            .lm-widget-tab{
                button{
                    color: inherit;
                    span{
                        color: inherit;
                    }
                }
                &:hover{
                    button{transform: scale(1.2);}
                }
                .p-button.p-button-icon-only{
                    padding-top: 0;
                    width: 2.3rem;
                    margin-right: 2px;
                }
            }
        }
    }

    .lm-widget-content{
        flex-grow: 1;
        overflow: hidden;
        position: relative;
        width: 100%;

        .lm-chart-widget-no-data{
            background: #fff;
            padding: .3rem 3rem;
            border-radius: 2rem;
            border: 1px solid #ccc;
        }


        .lm-widget-paginator{
            position: absolute;
            right: 0;
            bottom: 10px;
            left: auto;
            top: auto;
            .p-paginator{
                width: unset;
                background-color: transparent;
                .p-paginator-next{
                    margin-left: 5px !important;
                }
                .p-paginator-current{
                    color: #999;
                }
            }
        }

        .lm-widget-count-area{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #00aeba;
            font-size: 3rem;
            font-weight: bold;
            height: 100%;
        }


        .icon-tick-fill, .icon-box-approved, .icon-calendar, .icon-pudo-store{
            color: #00aeba;
        }
        .icon-heart{
            color: #ad1919;
        }

        .lm-widget-filter{
            text-align: center;
            background-color: #eee;
            border-radius: 10px;
            font-size: .9rem;

            &.lm-square{
                width: 70%;
                margin: 0 auto;
                .lm-square-content{
                    flex-direction: column;
                }
            }

            .lm-icon{
                padding-top: 5px !important;
                &:before{
                    color: #666;
                    font-size: 1.6rem;
                }
            }

            &._active{
                color: #fff;
                background-color: #00aeba;

                .lm-icon{
                    &:before{
                        color: #fff;
                    }
                }
            }
        }
    }
    
}