@import "~@ag-grid-community/core/dist/styles/ag-grid.css";
@import "~@ag-grid-community/core/dist/styles/ag-theme-material.css";
@import "cropperjs/dist/cropper.css";
@import "flatpickr/dist/themes/material_blue.css";
@import "~shepherd.js/dist/css/shepherd.css";
@import "@simonwep/pickr/dist/themes/monolith.min.css";

@import "abstracts/generic-classes";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "abstracts/helpers";
@import "base/colours";
@import "base/materialize-changes";
@import "base/nouislider-changes";
@import "base/shepherd-changes";
@import "base/typography";
@import "components/buttons";
@import "components/dots-animated";
@import "components/inputs";
@import "components/modals";
@import "components/progress-bars";
@import "components/scrollbar";
@import "layout/forms";
@import "layout/grids";
@import "layout/logins";
@import "layout/navs";
@import "layout/print";
@import "layout/map";
@import "shame";
// VERONA
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";
@import "prismjs/themes/prism-coy.css";
// @import "@fullcalendar/daygrid/main.min.css";
// @import "@fullcalendar/timegrid/main.min.css";
@import "../assets/demo/flags/flags.css";
@import "../assets/demo/documentation.scss";
@import "../assets/sass/_general";
@import "../assets/sass/overrides/layout_variables";
@import "../assets/sass/components/table-list";
@import "../assets/sass/components/_dashboard";
@import "../assets/sass/components/_widgets";
@import "../assets/sass/components/_paginator";
// PUDO
@import "../assets/sass/components/_pudo";
// ICON CLASSES
@import "../assets/sass/_icon-classes";
@import "../assets/sass/_lmfont";

// @mixin respond-to($breakpoint) {
//     @if $breakpoint == "lg" {
//       @media (min-width: 1920px) {
//         @content;
//       }
//     }
  
//     @else if $breakpoint == "xl" {
//       @media (min-width: 2420px) {
//         @content;
//       }
//     }
//   }


html {
    // filter: invert(1) hue-rotate(180deg);
    height: 100%;
    max-height: 100%;
}

body {
    height: 100%;
    overflow: hidden;
    background: white;
}

.main-container {
    overflow-y: scroll;
    height: calc(100vh - 65px);
}

#create-project-first-step-modal .datepicker-modal {
    width: 40vw;
}

#cancel-stop-point-modal .datepicker-modal {
    width: 40vw;
}

.ng-arrow-wrapper {
    position: absolute;
    right: 0;
}

.H_context_menu {
    color: $dark-grey;
    background-color: $off-white;
    border: 1px solid $light-grey;
    border-radius: 0.6em;
    padding: 5px 0;
}

.H_context_menu_item {
    font-size: 1.4em;
    line-height: 1.9em;
    color: $light-grey;
    height: 2.8vh;
    line-height: 2.8vh;

    &.clickable {
        color: $dark-grey;

        &:hover {
            color: $blue;
        }
    }

    &:hover {
        background-color: $off-white !important;
    }
}

@media only screen and (max-width: 1500px) {
    .H_context_menu_item {
        height: 2.3vh;
        line-height: 2.3vh;
        font-size: 1.2em;
    }
}

.H_context_menu_item_separator {
    margin: 0;
    border-top: none;
    border-bottom: 1px solid $light-grey;
}

.christmas-logo {
    background: url("/assets/lastmilyAssets/christmas-logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 45px;
    width: 55px;
    height: 73px;
}

.easter-logo {
    background: url("/assets/lastmilyAssets/easter-logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75px;
    width: 65px;
    height: 73px;
    margin-top: 4px;
}

.select-with-svg svg {
    width: 25px;
}

.compact-size-options.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 2vh 1vh;
}

.select-with-svg-option.compact-size {
    .icon-and-plate-combo {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.5vh;
        min-height: 100%;
        justify-content: center;
        flex: 1;

        .plate-number {
            height: 2vh;
            line-height: 2vh;
            color: black;
            font-size: 1rem !important;
        }
    }

    svg {
        width: 15px;
    }
}

.select-with-svg-option {
    display: flex;
    align-items: center;
    color: #333;
    height: 30px;

    span {
        padding-right: 5px;
    }

    &.compact-size {
        .icon {
            line-height: 15px;
            height: 15px;
        }
    }
}

.ng-value-label .select-with-svg-option {
    margin-top: 20px;

    &.compact-size {
        margin-top: 0;
    }
}

body #upscope___promo-link.upscope___promo-link--active {
    display: none !important;
}

iframe.high {
    bottom: 120px !important;
    max-height: calc(100% - 120px) !important;
}

iframe#chat_window_container.high,
iframe#side_window_container.high {
    bottom: 190px !important;
    max-height: calc(100% - 190px) !important;
}

.cropper-view-box {
    outline: none;
    border-radius: 100%;
}

.cropper-face {
    border-radius: 100%;
    border: 1px solid $blue;
}

.flatpickr-months .flatpickr-month {
    background: $blue;
    border-radius: 0;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    position: unset;
    background: $blue;

    &:hover {
        color: white;
        fill: white;
    }
}

span.flatpickr-weekday {
    background: $blue;
}

span.flatpickr-day.selected {
    background: $blue;
    border-color: $blue;

    &:hover {
        background: $blue;
        border-color: $blue;
    }
}

.flatpickr-weekdays {
    background: $blue;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: $blue;
    border-color: $blue;
}

#qr-container,
.qr-container {
    canvas {
        width: 100%;
        height: 100%;
    }
}

#vehicle-type-icon svg {
    width: 40px;

    path {
        fill: #999;
    }
}

.before-after-hover-box {
    display: none;
    position: fixed;
    background: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8em;
}

// this makes recaptcha invisible
.grecaptcha-badge {
    display: none;
}

// positions a button on the top left corner (for tests)
.debug-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;
    background-color: blue;
    color: white;
    border: 2px solid white;
    font-size: 24px;
    padding: 8px;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;
}

.debug-button:hover {
    background: rgba(0, 0, 200, 1);
}

.debug-button:active {
    background: rgba(0, 0, 145, 1);
}

.fix-smart-point-icon {
    position: absolute;
    bottom: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    background: #00aeba;
    border-radius: 100%;
    padding: 5px 7px;
}

.fix-smart-point-new-icon {
    position: absolute;
    bottom: -15px;
    left: -15px;
    width: 30px;
    height: 30px;
    background: #00aeba;
    border-radius: 100%;
    padding: 6px 7px;
}

.dashboard-box-new-button {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 43px;
    width: 32px;
    height: 32px;
    margin-left: 12%;
}