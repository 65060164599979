@font-face {
  font-family: 'lmfont';
  src:  url('fonts/lmfont.eot?86at3j');
  src:  url('fonts/lmfont.eot?86at3j#iefix') format('embedded-opentype'),
    url('fonts/lmfont.ttf?86at3j') format('truetype'),
    url('fonts/lmfont.woff?86at3j') format('woff'),
    url('fonts/lmfont.svg?86at3j#lmfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i[class^=icon-]:not([class~=pi]), i[class*=" icon-"]:not([class~=pi]),
span[class^=icon-]:not([class~=pi]), span[class*=" icon-"]:not([class~=pi]) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lmfont' !important;
  speak: never;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #ccc;
  font-size: 1.6rem !important;
  padding: 0 !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-partnersoutline:before {
  content: "\e98a";
}
.icon-active-point:before {
  content: "\e900";
}
.icon-bank:before {
  content: "\e901";
}
.icon-bot:before {
  content: "\e902";
}
.icon-box:before {
  content: "\e903";
}
.icon-box1:before {
  content: "\e904";
}
.icon-box-approved:before {
  content: "\e905";
}
.icon-box-sent:before {
  content: "\e906";
}
.icon-calendar:before {
  content: "\e907";
}
.icon-calendarclockfill:before {
  content: "\e908";
}
.icon-calendarclockoutline:before {
  content: "\e909";
}
.icon-capacity:before {
  content: "\e90a";
}
.icon-capacity_expand:before {
  content: "\e90b";
}
.icon-capacity_expand2:before {
  content: "\e90c";
}
.icon-cargo-truck:before {
  content: "\e90d";
}
.icon-chartheart .path1:before {
  content: "\e90e";
  color: rgb(204, 204, 204);
}
.icon-chartheart .path2:before {
  content: "\e90f";
  margin-left: -1.099609375em;
  color: rgb(204, 204, 204);
}
.icon-chartheart .path3:before {
  content: "\e910";
  margin-left: -1.099609375em;
  color: rgb(204, 204, 204);
}
.icon-chartheart .path4:before {
  content: "\e911";
  margin-left: -1.099609375em;
  color: rgb(217, 217, 217);
}
.icon-chartheart .path5:before {
  content: "\e912";
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-chartstar .path1:before {
  content: "\e913";
  color: rgb(204, 204, 204);
}
.icon-chartstar .path2:before {
  content: "\e914";
  margin-left: -1.099609375em;
  color: rgb(204, 204, 204);
}
.icon-chartstar .path3:before {
  content: "\e915";
  margin-left: -1.099609375em;
  color: rgb(204, 204, 204);
}
.icon-chartstar .path4:before {
  content: "\e916";
  margin-left: -1.099609375em;
  color: rgb(217, 217, 217);
}
.icon-chartstar .path5:before {
  content: "\e917";
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-chat:before {
  content: "\e918";
}
.icon-check-2:before {
  content: "\e919";
}
.icon-check:before {
  content: "\e91a";
}
.icon-clock:before {
  content: "\e91b";
}
.icon-coins:before {
  content: "\e91c";
}
.icon-company:before {
  content: "\e91d";
}
.icon-company1:before {
  content: "\e91e";
}
.icon-company2:before {
  content: "\e91f";
}
.icon-condo:before {
  content: "\e920";
}
.icon-Control:before {
  content: "\e921";
  color: #00aeba;
}
.icon-conversation:before {
  content: "\e922";
}
.icon-costpershipment:before {
  content: "\e923";
}
.icon-cross:before {
  content: "\e924";
}
.icon-dashboard:before {
  content: "\e925";
}
.icon-dashboard-menu:before {
  content: "\e926";
}
.icon-dashboardoutline:before {
  content: "\e927";
}
.icon-dashboardoutlineselected .path1:before {
  content: "\e928";
  color: rgb(217, 217, 217);
}
.icon-dashboardoutlineselected .path2:before {
  content: "\e929";
  margin-left: -1.2109375em;
  color: rgb(217, 217, 217);
}
.icon-dashboardoutlineselected .path3:before {
  content: "\e92a";
  margin-left: -1.2109375em;
  color: rgb(217, 217, 217);
}
.icon-dashboardoutlineselected .path4:before {
  content: "\e92b";
  margin-left: -1.2109375em;
  color: rgb(255, 0, 255);
}
.icon-dashboardoutlineselected .path5:before {
  content: "\e92c";
  margin-left: -1.2109375em;
  color: rgb(217, 217, 217);
}
.icon-dashboardoutlineselected .path6:before {
  content: "\e92d";
  margin-left: -1.2109375em;
  color: rgb(217, 217, 217);
}
.icon-deliverable:before {
  content: "\e92e";
}
.icon-delivery-box:before {
  content: "\e92f";
}
.icon-Delivery-days-1:before {
  content: "\e930";
}
.icon-Delivery-timeframe-1:before {
  content: "\e931";
}
.icon-depot:before {
  content: "\e932";
}
.icon-depotfill:before {
  content: "\e933";
}
.icon-depot-id:before {
  content: "\e934";
}
.icon-depotoutline:before {
  content: "\e935";
}
.icon-destination:before {
  content: "\e936";
}
.icon-destination-with-flag:before {
  content: "\e937";
}
.icon-direction:before {
  content: "\e938";
}
.icon-discount:before {
  content: "\e939";
}
.icon-dislike .path1:before {
  content: "\e93a";
  color: rgb(217, 217, 217);
}
.icon-dislike .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-dollar:before {
  content: "\e93c";
}
.icon-dollar-coin:before {
  content: "\e93d";
}
.icon-dollar-coins:before {
  content: "\e93e";
}
.icon-download:before {
  content: "\e93f";
}
.icon-driver:before {
  content: "\e940";
}
.icon-driversoutineselected .path1:before {
  content: "\e941";
  color: rgb(65, 195, 246);
}
.icon-driversoutineselected .path2:before {
  content: "\e942";
  margin-left: -1.052734375em;
  color: rgb(217, 217, 217);
}
.icon-driversoutineselected .path3:before {
  content: "\e943";
  margin-left: -1.052734375em;
  color: rgb(217, 217, 217);
}
.icon-driversoutline:before {
  content: "\e944";
}
.icon-duotone-icon-font-test-1 .path1:before {
  content: "\e945";
  color: rgb(0, 0, 0);
}
.icon-duotone-icon-font-test-1 .path2:before {
  content: "\e946";
  margin-left: -1.15625em;
  color: rgb(151, 71, 255);
}
.icon-email:before {
  content: "\e947";
}
.icon-excel:before {
  content: "\e948";
}
.icon-fee:before {
  content: "\e949";
}
.icon-finger-print:before {
  content: "\e94a";
}
.icon-finger-print-and-locker:before {
  content: "\e94b";
}
.icon-flags:before {
  content: "\e94c";
}
.icon-folder:before {
  content: "\e94d";
}
.icon-giveheart .path1:before {
  content: "\e94e";
  color: rgb(255, 91, 91);
}
.icon-giveheart .path2:before {
  content: "\e94f";
  margin-left: -1.16015625em;
  color: rgb(255, 91, 91);
}
.icon-giveheart .path3:before {
  content: "\e950";
  margin-left: -1.16015625em;
  color: rgb(249, 206, 160);
}
.icon-giveheart .path4:before {
  content: "\e951";
  margin-left: -1.16015625em;
  color: rgb(250, 211, 170);
}
.icon-giveheart .path5:before {
  content: "\e952";
  margin-left: -1.16015625em;
  color: rgb(5, 42, 117);
}
.icon-giveheart .path6:before {
  content: "\e953";
  margin-left: -1.16015625em;
  color: rgb(5, 42, 117);
}
.icon-giveheart .path7:before {
  content: "\e954";
  margin-left: -1.16015625em;
  color: rgb(5, 42, 117);
}
.icon-givemoney:before {
  content: "\e955";
}
.icon-givestar .path1:before {
  content: "\e956";
  color: rgb(255, 193, 7);
}
.icon-givestar .path2:before {
  content: "\e957";
  margin-left: -1.080078125em;
  color: rgb(255, 193, 7);
}
.icon-givestar .path3:before {
  content: "\e958";
  margin-left: -1.080078125em;
  color: rgb(253, 224, 190);
}
.icon-givestar .path4:before {
  content: "\e959";
  margin-left: -1.080078125em;
  color: rgb(247, 176, 115);
}
.icon-givestar .path5:before {
  content: "\e95a";
  margin-left: -1.080078125em;
  color: rgb(103, 204, 209);
}
.icon-givestar .path6:before {
  content: "\e95b";
  margin-left: -1.080078125em;
  color: rgb(255, 193, 7);
}
.icon-givestar .path7:before {
  content: "\e95c";
  margin-left: -1.080078125em;
  color: rgb(255, 193, 7);
}
.icon-givestar .path8:before {
  content: "\e95d";
  margin-left: -1.080078125em;
  color: rgb(255, 193, 7);
}
.icon-givestar .path9:before {
  content: "\e95e";
  margin-left: -1.080078125em;
  color: rgb(255, 193, 7);
}
.icon-givestar .path10:before {
  content: "\e95f";
  margin-left: -1.080078125em;
  color: rgb(255, 193, 7);
}
.icon-givestar .path11:before {
  content: "\e960";
  margin-left: -1.080078125em;
  color: rgb(255, 255, 255);
}
.icon-givestar .path12:before {
  content: "\e961";
  margin-left: -1.080078125em;
  color: rgb(5, 42, 117);
}
.icon-givestar .path13:before {
  content: "\e962";
  margin-left: -1.080078125em;
  color: rgb(5, 42, 117);
}
.icon-hand-heart:before {
  content: "\e963";
}
.icon-hand-people:before {
  content: "\e964";
}
.icon-hand-shake:before {
  content: "\e965";
}
.icon-heart:before {
  content: "\e966";
}
.icon-heart1:before {
  content: "\e967";
}
.icon-housecrossfill:before {
  content: "\e968";
}
.icon-housecrossoutline:before {
  content: "\e969";
}
.icon-id:before {
  content: "\e96a";
}
.icon-invoice:before {
  content: "\e96b";
}
.icon-invoicesoutline:before {
  content: "\e96c";
}
.icon-invoicesoutlineselected .path1:before {
  content: "\e96d";
  color: rgb(217, 217, 217);
}
.icon-invoicesoutlineselected .path2:before {
  content: "\e96e";
  margin-left: -0.7890625em;
  color: rgb(237, 125, 49);
}
.icon-job:before {
  content: "\e96f";
}
.icon-keyboard:before {
  content: "\e970";
}
.icon-kgrounded:before {
  content: "\e971";
}
.icon-leaf:before {
  content: "\e972";
}
.icon-like .path1:before {
  content: "\e973";
  color: rgb(0, 174, 239);
}
.icon-like .path2:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-like .path3:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-live-tracking:before {
  content: "\e976";
}
.icon-lm-logo-1:before {
  content: "\e977";
  color: #00aeba;
}
.icon-logo:before {
  content: "\e978";
}
.icon-map:before {
  content: "\e979";
}
.icon-message:before {
  content: "\e97a";
  color: #00aeba;
}
.icon-moneybag:before {
  content: "\e97b";
}
.icon-money-bag:before {
  content: "\e97c";
}
.icon-newshipmentoutline:before {
  content: "\e97d";
}
.icon-newshipmentsoutlineselected .path1:before {
  content: "\e97e";
  color: rgb(217, 217, 217);
}
.icon-newshipmentsoutlineselected .path2:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(217, 217, 217);
}
.icon-newshipmentsoutlineselected .path3:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(0, 255, 255);
}
.icon-newshipmentsoutlineselected .path4:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(0, 255, 255);
}
.icon-notacceptfill:before {
  content: "\e982";
}
.icon-notacceptoutline:before {
  content: "\e983";
}
.icon-notseenfill:before {
  content: "\e984";
}
.icon-notseenoutline:before {
  content: "\e985";
}
.icon-one:before {
  content: "\e986";
}
.icon-package:before {
  content: "\e987";
}
.icon-palette:before {
  content: "\e988";
}
.icon-parking:before {
  content: "\e989";
}
.icon-partnersoutlineselected .path1:before {
  content: "\e98b";
  color: rgb(217, 217, 217);
}
.icon-partnersoutlineselected .path2:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(175, 15, 94);
}
.icon-partnersoutlineselected .path3:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(175, 15, 94);
}
.icon-partnersoutlineselected .path4:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(175, 15, 94);
}
.icon-paymentsoutline:before {
  content: "\e98f";
}
.icon-paymentsoutlineselected .path1:before {
  content: "\e990";
  color: rgb(65, 195, 246);
}
.icon-paymentsoutlineselected .path2:before {
  content: "\e991";
  margin-left: -0.841796875em;
  color: rgb(217, 217, 217);
}
.icon-paymentsoutlineselected .path3:before {
  content: "\e992";
  margin-left: -0.841796875em;
  color: rgb(217, 217, 217);
}
.icon-paymentsoutlineselected .path4:before {
  content: "\e993";
  margin-left: -0.841796875em;
  color: rgb(217, 217, 217);
}
.icon-person:before {
  content: "\e994";
}
.icon-person-id:before {
  content: "\e995";
}
.icon-pickup:before {
  content: "\e996";
}
.icon-pin:before {
  content: "\e997";
}
.icon-pin1:before {
  content: "\e998";
  color: #00aeba;
}
.icon-pincrossfill:before {
  content: "\e999";
}
.icon-pincrossoutline:before {
  content: "\e99a";
}
.icon-pinfill:before {
  content: "\e99b";
}
.icon-pinoutline:before {
  content: "\e99c";
}
.icon-pintime:before {
  content: "\e99d";
}
.icon-print:before {
  content: "\e99e";
}
.icon-privacy:before {
  content: "\e99f";
}
.icon-project:before {
  content: "\e9a0";
}
.icon-projectoutline:before {
  content: "\e9a1";
}
.icon-projectoutlineselected .path1:before {
  content: "\e9a2";
  color: rgb(24, 194, 198);
}
.icon-projectoutlineselected .path2:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(217, 217, 217);
}
.icon-pudooutline:before {
  content: "\e9a4";
}
.icon-pudooutlineselected .path1:before {
  content: "\e9a5";
  color: rgb(109, 183, 31);
}
.icon-pudooutlineselected .path2:before {
  content: "\e9a6";
  margin-left: -1em;
  color: rgb(217, 217, 217);
}
.icon-pudo-store:before {
  content: "\e9a7";
}
.icon-qr-code:before {
  content: "\e9a8";
}
.icon-recipientsoutline:before {
  content: "\e9a9";
}
.icon-recipientsoutlineselected .path1:before {
  content: "\e9aa";
  color: rgb(216, 234, 34);
}
.icon-recipientsoutlineselected .path2:before {
  content: "\e9ab";
  margin-left: -1.052734375em;
  color: rgb(216, 234, 34);
}
.icon-recipientsoutlineselected .path3:before {
  content: "\e9ac";
  margin-left: -1.052734375em;
  color: rgb(216, 234, 34);
}
.icon-recipientsoutlineselected .path4:before {
  content: "\e9ad";
  margin-left: -1.052734375em;
  color: rgb(217, 217, 217);
}
.icon-recipientsoutlineselected .path5:before {
  content: "\e9ae";
  margin-left: -1.052734375em;
  color: rgb(217, 217, 217);
}
.icon-Reschedule-1:before {
  content: "\e9af";
}
.icon-road:before {
  content: "\e9b0";
}
.icon-robotoutline:before {
  content: "\e9b1";
}
.icon-settingsoutline:before {
  content: "\e9b2";
}
.icon-settingsoutlineselected .path1:before {
  content: "\e9b3";
  color: rgb(217, 217, 217);
}
.icon-settingsoutlineselected .path2:before {
  content: "\e9b4";
  margin-left: -1.10546875em;
  color: rgb(30, 144, 255);
}
.icon-shippersoutline:before {
  content: "\e9b5";
}
.icon-shippersoutlineselected .path1:before {
  content: "\e9b6";
  color: rgb(0, 255, 255);
}
.icon-shippersoutlineselected .path2:before {
  content: "\e9b7";
  margin-left: -0.947265625em;
  color: rgb(0, 255, 255);
}
.icon-shippersoutlineselected .path3:before {
  content: "\e9b8";
  margin-left: -0.947265625em;
  color: rgb(0, 255, 255);
}
.icon-shippersoutlineselected .path4:before {
  content: "\e9b9";
  margin-left: -0.947265625em;
  color: rgb(217, 217, 217);
}
.icon-shippersoutlineselected .path5:before {
  content: "\e9ba";
  margin-left: -0.947265625em;
  color: rgb(217, 217, 217);
}
.icon-shippersoutlineselected .path6:before {
  content: "\e9bb";
  margin-left: -0.947265625em;
  color: rgb(217, 217, 217);
}
.icon-shippersoutlineselected .path7:before {
  content: "\e9bc";
  margin-left: -0.947265625em;
  color: rgb(217, 217, 217);
}
.icon-shippersoutlineselected .path8:before {
  content: "\e9bd";
  margin-left: -0.947265625em;
  color: rgb(217, 217, 217);
}
.icon-shippersoutlineselected .path9:before {
  content: "\e9be";
  margin-left: -0.947265625em;
  color: rgb(217, 217, 217);
}
.icon-star:before {
  content: "\e9bf";
}
.icon-star1:before {
  content: "\e9c0";
  color: #ffc107;
}
.icon-stoppointoutline:before {
  content: "\e9c1";
}
.icon-stoppointprocessing:before {
  content: "\e9c2";
}
.icon-stoppointsoutline:before {
  content: "\e9c3";
}
.icon-stoppointsoutlineselected .path1:before {
  content: "\e9c4";
  color: rgb(217, 217, 217);
}
.icon-stoppointsoutlineselected .path2:before {
  content: "\e9c5";
  margin-left: -0.849609375em;
  color: rgb(153, 0, 255);
}
.icon-TAX:before {
  content: "\e9c6";
}
.icon-teamoutline:before {
  content: "\e9c7";
}
.icon-teamoutlineselected .path1:before {
  content: "\e9c8";
  color: rgb(217, 217, 217);
}
.icon-teamoutlineselected .path2:before {
  content: "\e9c9";
  margin-left: -1.2626953125em;
  color: rgb(217, 217, 217);
}
.icon-teamoutlineselected .path3:before {
  content: "\e9ca";
  margin-left: -1.2626953125em;
  color: rgb(217, 217, 217);
}
.icon-teamoutlineselected .path4:before {
  content: "\e9cb";
  margin-left: -1.2626953125em;
  color: rgb(217, 217, 217);
}
.icon-teamoutlineselected .path5:before {
  content: "\e9cc";
  margin-left: -1.2626953125em;
  color: rgb(225, 111, 69);
}
.icon-teamoutlineselected .path6:before {
  content: "\e9cd";
  margin-left: -1.2626953125em;
  color: rgb(225, 111, 69);
}
.icon-telephone:before {
  content: "\e9ce";
}
.icon-tick-fill:before {
  content: "\e9cf";
}
.icon-timecheck:before {
  content: "\e9d0";
}
.icon-timer:before {
  content: "\e9d1";
}
.icon-Time-range-1:before {
  content: "\e9d2";
}
.icon-timewindowprocessing:before {
  content: "\e9d3";
}
.icon-TIN:before {
  content: "\e9d4";
}
.icon-tolls:before {
  content: "\e9d5";
}
.icon-tree:before {
  content: "\e9d6";
  color: #00aeef;
}
.icon-Truck-icon:before {
  content: "\e9d7";
}
.icon-upload:before {
  content: "\e9d8";
}
.icon-vehicle:before {
  content: "\e9d9";
}
.icon-vehiclesoutline:before {
  content: "\e9da";
}
.icon-vehiclesoutlineselected .path1:before {
  content: "\e9db";
  color: rgb(237, 125, 49);
}
.icon-vehiclesoutlineselected .path2:before {
  content: "\e9dc";
  margin-left: -1.25em;
  color: rgb(237, 125, 49);
}
.icon-vehiclesoutlineselected .path3:before {
  content: "\e9dd";
  margin-left: -1.25em;
  color: rgb(217, 217, 217);
}
.icon-vehiclesoutlineselected .path4:before {
  content: "\e9de";
  margin-left: -1.25em;
  color: rgb(217, 217, 217);
}
.icon-warehouseoutine:before {
  content: "\e9df";
}
.icon-warehouseoutlineselected .path1:before {
  content: "\e9e0";
  color: rgb(0, 174, 186);
}
.icon-warehouseoutlineselected .path2:before {
  content: "\e9e1";
  margin-left: -1.0498046875em;
  color: rgb(0, 174, 186);
}
.icon-warehouseoutlineselected .path3:before {
  content: "\e9e2";
  margin-left: -1.0498046875em;
  color: rgb(217, 217, 217);
}
.icon-warehouseoutlineselected .path4:before {
  content: "\e9e3";
  margin-left: -1.0498046875em;
  color: rgb(217, 217, 217);
}
.icon-warehouseoutlineselected .path5:before {
  content: "\e9e4";
  margin-left: -1.0498046875em;
  color: rgb(0, 174, 186);
}
.icon-warehouseoutlineselected .path6:before {
  content: "\e9e5";
  margin-left: -1.0498046875em;
  color: rgb(0, 174, 186);
}
.icon-way:before {
  content: "\e9e6";
}
.icon-weight:before {
  content: "\e9e7";
}
.icon-weight-kg:before {
  content: "\e9e8";
}
.icon-working-hours:before {
  content: "\e9e9";
}
.icon-working-time:before {
  content: "\e9ea";
}
