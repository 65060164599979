@import 'styles/abstracts/variables';

.shepherd-element {
    background: #fafafa;
    border-radius: 5px;
}

.shepherd-content {
    border-radius: 0px;
}

.shepherd-has-title .shepherd-content .shepherd-header {
    background: #fafafa;
    padding: 1.5em 1.5em 0;
}

.shepherd-header {
    border-radius: 0;
}

.shepherd-title {
    color: black;
    font-size: 1.1rem;
    font-weight: 600;
}

.shepherd-text {
    padding: 1.5em;
    font-weight: 400;
}

.shepherd-footer {
    padding: 0;
    border-radius: 0;
}

.shepherd-button {
    font-weight: 600;
}

.shepherd-button:not(:disabled) {
    background: $blue;
    color: white;
    flex: 1;
    margin: 0;
    border-radius: 0;
    padding: 1.5em;

    &:hover,
    &:focus,
    &:active {
        background: #05c5d2;
        background: $blue;
    }
}

.shepherd-button.shepherd-button-secondary {
    background: #eaeaea;
    color: #666;
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
    font-weight: 600;
    margin-top: -10px;
    color: #ccc;

    &:hover {
        color: #666;
    }

    &:focus {
        background-color: transparent;
    }
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
    opacity: 0;
}

.stops-icon-in-tour {
    height: 100px;
    margin: auto;
    background: url(/assets/lastmilyAssets/stop-styles.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
