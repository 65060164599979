@font-face {
    font-family: "Qanelas";
    src: url("https://db.onlinewebfonts.com/t/5b2384cf9c701d542008dfe9c848d9d6.eot");
    src: url("https://db.onlinewebfonts.com/t/5b2384cf9c701d542008dfe9c848d9d6.eot?#iefix") format("embedded-opentype"),
        url("https://db.onlinewebfonts.com/t/5b2384cf9c701d542008dfe9c848d9d6.woff2") format("woff2"),
        url("https://db.onlinewebfonts.com/t/5b2384cf9c701d542008dfe9c848d9d6.woff") format("woff"),
        url("https://db.onlinewebfonts.com/t/5b2384cf9c701d542008dfe9c848d9d6.ttf") format("truetype"),
        url("https://db.onlinewebfonts.com/t/5b2384cf9c701d542008dfe9c848d9d6.svg#Qanelas") format("svg");
}

html {
    font-family: "lato", sans-serif;
}

.brand-logo small {
    font-size: 40%;
}

.bold-letters {
    font-weight: 600;
}

.text-center {
    text-align: center;
}

.green-letters {
    color: green;
}

.red-letters {
    color: red;
}

h3 {
    font-size: 2rem;
}

a {
    color: $blue;
}

.link-sent-by-user { 
    color: white;
    text-decoration: underline;
}

.link-sent-by-opponent { 
    color: $blue;
    text-decoration: underline;
}

.text-right {
    text-align: right;
}

.list-container {
    padding-left: 15px;
}

ul.decimal-list:not(.browser-default) {
    list-style-type: decimal;

    & > li {
        list-style-type: decimal;
    }
}

.required-field {
    color: $blue;
}

.line-height-30 {
    line-height: 30px;
}

.nowrap {
    white-space: nowrap;
}

.list-item {
    display: list-item;
    list-style-position: inside;
}

.grid-context-menu-item {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}