lm-dashboard{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #fafafa;

    *{box-sizing: border-box;}
}


.lm-dashboard-header{
    height: 60px;
    background: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #efefef;
    padding-left: 1.3rem;
    padding-right: 1.3rem;

    .lm-dashboard-header-section{
        display: flex;
        flex: 1;
        align-items: center;
        // height: 60%;

        // .lm-period-btn{
        //     color: ;
        // }
        .lm-dashboard-header-section-date-display{
            background-color: #00aeba;
            color: #fff;
            margin: 6px 25px 5px 40px;
            white-space: nowrap;
            cursor: pointer;
        }
        .lm-period-btn.active{
            button{
                background-color:#00aeba !important;
                color: #fff !important;
            }
        }

        .lm-button{
            padding: .5rem 1.5rem;
            margin-left: 1rem;
            background: #f6f6f6;
            color: inherit;
            border-radius: 20px;
            border: 1px solid #eee;
    
            .p-button-label{
                font-weight: normal;
                // color: #fff !important;
            }
    
            &:hover, &.active{
                color: #fff !important;
                background: #00aeba;
                .p-button-label{
                    color: #fff !important;
                }
            }
        }
        lm-button{
            &.--sink{
                .lm-button{
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    z-index: -1;
                }
            }

        }
        .lm-dropdown-footer-button{
            .lm-button{
                border: none;
            }
        }
    }

    .lm-dashboard-title{
        font-weight: 500;
        margin: 0;
        font-size: 1.7rem;
    }

    .lm-dashboard-search{
        display: block;
        height: 60px;
        width: 70%;

        .grid{
            margin: 0;

            &>div{
                height: 60px;
            }
        }
        .p-inputtext{
            margin: 0;
            // height: 20px;
            // border-radius: 10px;
        }
        .p-autocomplete-panel{
            left: 0 !important;
            top: 40px;
        }
    }

    .lm-dashboard-header-dropdown{
        display: inline-flex;
        // padding-top: 8px;
        &>.grid{margin-top: 0;}

        .lm-dropdown-control{
            margin-bottom: 0;
        }
        .lm-multi-select-control{
            margin-bottom: 0;
            border-radius: 2rem;
            padding: .4rem !important;

            .p-multiselect-label-container{
                max-width: 20vw;
                padding-right: .7rem;
            }

            .p-multiselect-trigger{
                line-height: 69%;
                width: 1.2rem;
                padding-right: .3rem;
            }

            i.pi:not(.pi-spinner){
                font-size: 1rem;
                width: 38px;
                right: 22px;
                background: #f6f6f6;
                top: 0px;
                bottom: 0px;
                display: flex;
                align-items: center;
                border-radius: 4rem;
                height: unset;
                margin-top: 0;
                justify-content: center;
                color: indianred;
                border-left: 1px solid #ccc !important;
                box-shadow: -4px 0px 3px rgba(0, 0, 0, .2);
            }
        }


        .lm-multi-select-panel{   
            border-radius: 1rem;
            border: 1px solid #eee;
            margin-top: .5rem;

            .p-multiselect-header{
                border-top-left-radius: 1rem;
                 border-top-right-radius: 1rem;
                 padding: 0.3rem 0.5rem;
            }

            .p-multiselect-items{
                padding: 0.2rem 0;
                .p-multiselect-item{
                    padding: .5rem;
                }
            }
            
        }
        
        .p-multiselect-label{
            height: 23px !important;
            
        }
        .p-multiselect-token{
            border-radius: 2rem;
            font-size: .85rem;
        }
        .p-float-label{
            margin-left: 0;
        }
    }

    
    
}

.lm-dashboard-swagger-btn{
    .lm-button{
        padding: 0.34rem 0.7rem 0.34rem 0.4rem;
        margin-left: 1rem;
        background: #00aeba;
        color: #fff !important;
        border-radius: 20px;
        // width: 25px;

        .p-button-icon{
            font-size: 1rem;
            margin-right: 0.4rem;
            color: #fff;
        }

        .p-button-label{
            font-size: .83rem;
            font-weight: 300;
            color: #fff !important;
        }

        &:hover{
            padding-left: .6rem;
            // width: 61px;
            opacity: 1;
            background: #00aeba;
            .p-button-icon{
                margin-right: 0.3rem;
            }
        }
    }
    &.lm-dashboard-app-swagger-btn{
        .lm-button{
            margin-left: 0;
        }
    }
}

.lm-dashboard-sidebar{
    top: 124px;
    left: 0;
    width: 140px;
    bottom: 0;
    position: absolute;
    background: #ececec;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;

    .p-button{
        width: 100%;
        background-color:transparent;
        color: #495057;
        
        .p-button-label{
            font-size: 1.1rem;
            font-weight: normal;
        }
        .p-button-icon{
            font-size: 1.1rem;
            color: #777;
        }

        &:hover{
            background-color: transparent;
            color: #00aeba;
        }
        &.active{
            color:#00aeba;
        }
    }
}
.lm-dashboard-content{
    top: 124px;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: #fafafa;
    padding: 1.3rem;
    padding-bottom: 70px;

    .lm-dashboard-widget-area{
        // height: 100%;
        width: 100%;
        // display: flex;

        .lm-cancelation-widget{
            .lm-cancelation-widget-mask{
                margin-top: 10px;
                margin-bottom: 20px;
                padding-right: 10px;
                height: 310px;
            }

        }
    }

    .lm-dashboard-widget-sidebar{
        position: fixed;
        top: 124px;
        right: 0;
        left: auto;
        width: 25%;
        bottom: 1rem;
        padding: 1.5rem 1.5rem 1rem 0;
        display: flex;
        flex-direction: column;
    }

    .lm-dashboard-settings-wrapper{
        position: relative;
        height: 100%;


        .lm-dashboard-settings-header{
            height: 30px;
            position: absolute;
            top: 0;
            bottom: auto;
            left: 0;
            right: 0;

            .lm-dashboard-settings-title{
                font-size: 1.4rem;
            }
        }

        .lm-dashboard-settings-content{
            position: absolute;
            top: 60px;
            bottom: 60px;
            left: 0;
            right: 0;
            overflow: auto;
        }

        .lm-dashboard-settings-footer{
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            height: 60px;

            .lm-dashboard-settings-title{
                font-size: 1.4rem;
            }
        }
    }
        

}
