@import 'styles/abstracts/variables';

.display-flex {
    display: flex !important;
}

.display-block {
    display: block;
}

.display-inline-block {
    display: inline-block;
}

.display-inline {
    display: inline;
}

.hidden {
    display: none !important;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.row-reverse {
    flex-direction: row-reverse;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-column {
    flex-direction: column;
}

.clear-both {
    clear: both;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.margin-auto {
    margin: auto;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.fill-evenodd {
    fill-rule: evenodd;
}

.margin-top-2rem {
    margin-top: 2rem;
}

.margin-bottom-2rem {
    margin-bottom: 2rem;
}

.margin-top-5px {
    margin-top: 5px;
}

.margin-right-5px {
    margin-right: 5px;
}

.margin-5px {
    margin: 5px;
}

.padding-left-5px {
    padding-left: 5px;
}

.font-size-1-1em {
    font-size: 1.1em;
}

.width-100 {
    width: 100%;
}

.height-100 {
    height: 100%;
}

.fullscreen {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

@media(max-width:767px) {

    .fullscreen {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-visibility {
    visibility: hidden;
}

.link:not(.disabled) {
    color: $blue;
    cursor: pointer;

    &.disabled {
        color: $medium-grey;
    }

    &.underlined {
        text-decoration: underline;
    }
}
