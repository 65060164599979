@import '../abstracts/variables';

.blue-letters {
    color: $blue;
}

.grey-letters {
    color: $medium-grey;
}

.light-grey-letters {
    color: $light-grey;
}

.medium-red-letters {
    color: $medium-red;
}

.white-icon {
    fill: $white;
}

.grey-icon {
    fill: $medium-grey;
}

.dark-grey-icon {
    fill: $dark-grey;
}

.light-grey-icon {
    fill: $light-grey;
}

.black-icon {
    fill: $nav-black;
}

.blue-icon {
    fill: $blue;
}

.grey-letters {
    color: $medium-grey;
}

.dark-grey-letters {
    color: $dark-grey;
}

.ag-theme-material .ag-row.grey-letters {
    color: $medium-grey;
}

.svg-shadow {
    -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
}