.p-paginator{
    padding: 0;
    width: 3.7rem;
    justify-content: space-between !important;
    button{
        width: 1.7rem !important;
        height: 1.7rem !important;
        min-width: unset !important;
        color: #000 !important;
        border: 1px solid !important;
        margin: 0 !important;
        -webkit-transition: all 200ms ease-out !important;
        -moz-transition: all 200ms ease-out !important;
        -o-transition: all 200ms ease-out !important;
        transition: all 200ms ease-out !important;

        .p-paginator-icon {
            -webkit-transition: all 200ms ease-out !important;
            -moz-transition: all 200ms ease-out !important;
            -o-transition: all 200ms ease-out !important;
            transition: all 200ms ease-out !important;
        }

        &:hover{
            color: #000 !important;
            background: unset !important;
            border-color: #000 !important;
            opacity: .5 !important;
            
        }

        &.p-paginator-next{
            &:hover{
                // transform: translateX(5px);
                // width: 1.85rem !important;
                // margin-left: -0.15rem !important;
                .pi-angle-right{
                    transform: translateX(4px)
                }
            }
        }
        &.p-paginator-prev{
            &:hover{
                // transform: translateX(-5px);
                // width: 1.85rem !important;
                // margin-right: -0.15rem !important;
                .pi-angle-left{
                    transform: translateX(-4px)
                }
            }
        }

        &.p-disabled{
            color: #000;
            background: unset;
            border-color: #000;
            opacity: .3;
        } 
    }
}