//https://gs.statcounter.com/screen-resolution-stats/desktop/worldwide
html, body {
    // min-width: 1280px;
    // overflow-x: auto; 
    position: relative;
}

@media print {
    body {
        min-width: unset !important;
    }
}

[class*="lm-"]{
    box-sizing: border-box;
}
body, h1, h2, h3, h4, h5, h6, .p-component, .p-inputtext,
button, input, optgroup, select, textarea{
    font-family: 'Roboto', sans-serif !important
}
.pi{font-family: 'primeicons' !important;}
.lm-icon{font-family: 'lmfont' !important;}
.lm-input-desc{
    margin-top: 5px;
    font-size: 12px;
    opacity: 0.7;
}

.lm-routing-settings-modal {
    #routing-settings-modal-button.routing-settings-modal-button-to-close{
        // display: none;
    }
    .p-dialog-mask{
        align-items: end;
        .p-dialog-left .p-dialog{
            opacity: 1;
            
        }
    }
    .lm-dialog-discard-left{

        .p-dialog{
            -webkit-transition: all 1s ease-out !important;
            -moz-transition: all 1s ease-out !important;
            -o-transition: all 1s ease-out !important;
            transition: all 1s ease-out !important;
            transform: translateX(-100vw);
            // opacity: 0;
        }
        
    }
}
.p-inputtext{
    border: none
}

.lm-double-control{
    & > [class*="lm-double-control"]{
        display: block;
    }
    .lm-double-control-first{
        padding-right: 0 !important;

        & > [class*=col]{ 
            padding-right: 0 !important; 
        }
        .p-inputtext{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-left: 5px;
            padding-right: 5px;
            flex: unset;
        }
        .lm-dropdown-control{
            padding-left: 5px;
        }
    }
    .lm-double-control-second{
        padding-left: 0 !important;

        .p-float-label{
            margin-left: 0; padding-left: 0;
        }
        .lm-pdl10{ 
            padding-left: 0 !important; 
        }

        .p-inputtext{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-color: #d6d6d6 !important;
        }

        .grid {
            & > [class*=col]{ 
                padding-left: 0 !important; 
            }
        }
        
        
    }
}


.lm-dropdown-footer{
    width: 100%;
    height: 0;
    background-color: #f6f6f6;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;
    color: unset;

    .lm-dropdown-footer-button{
        .lm-button{
            margin:0 !important;
            border: none;
        }
    }
}
.lm-dropdown-footer-enabled{
    .lm-dropdown-footer{
        height: 40px;
        border-top: 1px solid #eee;
    }
    .p-multiselect-close{
        display: none;
    } 
}

.lm-dropdown-panel{
    .p-dropdown-filter{
        padding-right: 0 !important;
    }
} 

.lm-input-hint {
    color: #999;
    font-size: .8em;
    position: absolute;
    bottom: -12px;
    left: 10px;
}

.lm-input-hint {
    color: #999;
    font-size: .8em;
    position: absolute;
    top: 100%;
    left: 10px;
}
input:not([type]):disabled, input:not([type]):read-only{
    color: unset !important;
    border-bottom: 1px solid #eee!important;
}

// this is removed because it messes up inputs
// input[type=text]:not(.browser-default):focus:not([readonly]),
// input[type=text]:not(.browser-default){
//     border: none !important;
//     box-shadow: none !important;
// }

.col-2-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 20%;
}

.lm-toast-z-index{z-index: 2 !important;}

.price-list-link {
    cursor: pointer;

    &:hover {
        color: #00aeba;
    }
}

.p-button:focus{
    box-shadow: none;
}

.ag-theme-material .ag-row-selected:before{
    background-color: #fff !important;
}

#map-tools {
    position: absolute;
    top: 65px;
    margin: 5px;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 0px 1px #ccc;
}

.multi-select-wrap p-multiselectitem span {
    text-wrap: auto;
}