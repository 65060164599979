@import "styles/abstracts/variables";

.upload-area {
    height: 50vh;
    border: 1px dotted $blue;
}

.input-field .ng-select-container,
.materialize-ngselect .ng-select-container {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    outline: none;
    height: 3rem;
    line-height: 3rem;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 0;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1;
    border-radius: 0;
}

.ng-dropdown-panel {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border: none;

    .ng-dropdown-panel-items .ng-option {
        font-size: 16px;
        color: #26a69a;
        line-height: 22px;
        padding: 14px 16px;

        &.ng-option-marked {
            background-color: $light-grey;
            color: #26a69a;
        }
    }
}

.s2,
.s3,
.s4 {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        padding: 2vh 1vh;
        font-size: 0.8em;
    }

    .ng-select .ng-select-container .ng-value-container {
        font-size: 0.8em;
    }
}

.small-dropdown {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        padding: 2vh 1vh;
        font-size: 0.8em;
    }
}

input[type="text"]:not(.browser-default).small-input {
    width: 30%;
    margin-right: 15px;
}

.input-field.disabled {
    position: relative;

    &:after {
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 9;
    }

    label {
        color: #9e9e9e82;
    }
}

.custom-address-field,
.custom-input-field {
    margin-bottom: 0;

    .ng-select-container {
        visibility: hidden;
        position: absolute !important;
    }
}

.ngx-form-icon {
    height: 18px;
    width: 18px;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
}

.ngx-form-checkbox input:checked+.ngx-form-icon {
    background: none;
    border-color: unset;
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    box-shadow: none;

    &:active &:focus {
        box-shadow: none;
    }
}

#drivers-select-stops-grid {
    width: 10vw;
    margin-top: 15px;
}

#project-problem-select {
    margin-right: 20px;
    width: 13vw;
}

#stop-point-fulfillment-status-select {
    width: 13vw;
    margin-left: auto;
}

[type="radio"].checked+span:before {
    border: 2px solid transparent;
}

[type="radio"].checked+span:after,
[type="radio"].with-gap.checked+span:after {
    background-color: #26a69a;
}

[type="radio"].checked+span:after {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

[type="radio"].checked+span:after,
[type="radio"].with-gap.checked+span:before,
[type="radio"].with-gap.checked+span:after {
    border: 2px solid #26a69a;
}

.ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 0;
}

#old-route-switch {
    position: fixed;
    bottom: 100px;
    left: 10px;

    label.title {
        color: $blue;
        font-size: 1em;
        font-weight: 600;
        display: block;
    }

    label.states {
        color: $dark-grey;
    }
}

.ng-select-height-fix {
    margin-top: calc(1rem + 10px);
}

.form-label-icon {
    position: absolute;
    left: -20px;
    top: 15px;
    color: #333;
}

#wizard-content {
    .row {
        margin-bottom: 10px;
    }

    .input-field {
        margin-top: 0;
        margin-bottom: 0;
    }

    .input-field>label {
        color: #333;
        transform: translateY(10px);
    }

    nouislider+.helper-text {
        margin-top: -5px;
        margin-bottom: 10px;
    }

    .input-field>label:not(.label-icon).active {
        transform: translateY(-14px) scale(0.8);
        transform-origin: 0 0;
    }

    input:not([type]),
    input[type="text"]:not(.browser-default),
    input[type="password"]:not(.browser-default),
    input[type="email"]:not(.browser-default),
    input[type="url"]:not(.browser-default),
    input[type="time"]:not(.browser-default),
    input[type="date"]:not(.browser-default),
    input[type="datetime"]:not(.browser-default),
    input[type="datetime-local"]:not(.browser-default),
    input[type="tel"]:not(.browser-default),
    input[type="number"]:not(.browser-default),
    input[type="search"]:not(.browser-default),
    textarea.materialize-textarea {
        height: 36px;
    }
}

#project-drivers-select .ng-select-container {
    height: unset;
}

#white-bar-range-select, #white-bar-search-type-select {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        white-space: unset;
    }

    .ng-value-label {
        margin-left: 8px;
    }
}

.ng-select.ng-select-opened>.ng-select-container {
    background: transparent;
    border-color: #b3b3b3 #ccc #d9d9d9;
}

#stop-point-fulfillment-status-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: unset;
}

.input-field .label-select .ng-select-container {
    border-bottom: none;
    color: #9e9e9e;
    font-size: 0.9em;

    &:hover {
        box-shadow: none;
    }
}

.label-select.ng-select {
    position: absolute;
    top: -10px;
}

.ng-select.ng-select-disabled .ng-value {
    color: $medium-grey;
}

#drivers-select-stops-grid {
    &.materialize-ngselect .ng-select-container {
        border-color: #2d2d2d;
    }

    .ng-select .ng-select-container .ng-value-container .ng-placeholder {
        color: #2d2d2d;
        font-size: 0.8em;
        height: 40px;
    }

    .ng-select .ng-arrow-wrapper .ng-arrow {
        border-color: #2d2d2d transparent transparent;
    }
}

.small-grey-checkbox {
    [type="checkbox"]+span:not(.lever) {
        font-size: 1.3em;
        padding-left: 20px;
        line-height: 20px;
        color: black;
    }

    [type="checkbox"]+span:not(.lever):before,
    [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
        width: 10px;
        height: 10px;
        border-color: #9e9e9e;
    }

    [type="checkbox"]:checked+span:not(.lever):before {
        width: 5px;
        height: 10px;
        top: 0;
    }

    [type="checkbox"]:checked+span:not(.lever):before {
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #26a69a;
        border-bottom: 2px solid #26a69a;
    }
}

.small-grey-radio {

    [type="radio"]:not(:checked)+span,
    [type="radio"]:checked+span {
        font-size: 1.3em;
        height: 20px;
        line-height: 20px;
        color: black;
        padding-left: 22px;
    }

    [type="radio"]+span:before,
    [type="radio"]+span:after {
        width: 10px;
        height: 10px;
    }

    [type="radio"]:not(:checked)+span:before,
    [type="radio"]:not(:checked)+span:after {
        border-color: #9e9e9e;
    }

    [type="radio"].checked+span:after,
    [type="radio"].with-gap.checked+span:before,
    [type="radio"].with-gap.checked+span:after {
        border-color: #26a69a;
    }
}

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: unset;
    border-color: #ddd;
}

input.valid.ng-touched.ng-dirty:not([type]),
input.valid.ng-touched.ng-dirty:not(.browser-default) {
    border-bottom: 1px solid #9e9e9e;
    box-shadow: none;
}

input.ng-valid.ng-touched.ng-dirty:not([type]),
input.ng-valid.ng-touched.ng-dirty:not(.browser-default) {
    border-bottom: 1px solid #4caf50;
    box-shadow: 0 1px 0 0 #4caf50;
}

input.ng-invalid.ng-touched.ng-dirty:not([type]),
input.ng-invalid.ng-touched.ng-dirty:not(.browser-default) {
    border-bottom: 1px solid red;
    box-shadow: 0 1px 0 0 red;
}

.input-field>input[type]:-webkit-autofill:not(.browser-default):not([type="search"])+label,
.input-field>input[type="date"]:not(.browser-default)+label,
.input-field>input[type="time"]:not(.browser-default)+label {
    transform: translateY(-16px) scale(0.8);
    transform-origin: 0 0;
}

.ng-select-multiple .ng-select-container.ng-has-value {
    height: unset;
}

// remove number fields arrows
.no-arrows-number-field::-webkit-outer-spin-button,
.no-arrows-number-field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-error-label {
    position: absolute;
    top: 64%;
    left: 6%;
    font-size: 11px;
    color: red;
    user-select: none;
}

.form-error-input {
    border-bottom: 1px solid red!important;
    box-shadow: 0 1px 0 0 red!important;
}

.p-dropdown {
    background: #f3f3f3;
    border: none;
}

.p-inputtext {
    color: #666;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #00aeba;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: #666;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #00aeba;
}