/* Add your variable customizations of layout here */

// $sm:576px !default;
// $md:768px !default;
// $lg:1920px !default;
// $xl:2420px !default;


//     $breakpoints: (
//         'sm': $sm,
//         'md': $md,
//         'lg': $lg,
//         'xl': $xl
//     );
