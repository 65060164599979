@import 'styles/abstracts/variables';

.main-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 92vh;
}

.login-button {
    height: calc(8vh + 1px);
    width: calc(100% + 1px);
}

.image-background {
    background: url('/assets/lastmilyAssets/BGcrossroad40jpeg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.christmas {
        background: url('/assets/lastmilyAssets/christmas.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

@media(max-width:767px) {

    .image-background christmas {
        display: none;
    }
}

.logo-text {
    font-family: Qanelas, sans-serif;
    font-size: 6vh;
    cursor: default;

    small {
        font-size: 40%;
    }
}

.welcome-text {
    font-size: 6vh;
    font-weight: 600;
}

.register-text {
    font-size: 2.4vh;
    color: $dark-grey;
}

.login-with-container {
    font-size: 2.5vh;
    color: $medium-grey;
    visibility: hidden;
}

.logo {
    width: 4vh;
    height: 4vh;
    margin: 10px 35px 0;
    cursor: pointer;

    &.google {
        background: url('/assets/lastmilyAssets/google_PNG19630.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &.facebook {
        background: url('/assets/lastmilyAssets/facebook_logos_PNG19750.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &.linkedin {
        background: url('/assets/lastmilyAssets/linkedIn_PNG16.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}

.forgot-password {
    margin-top: -1vh;
    font-size: .9em;
    color: $blue;
    cursor: pointer;
}

.line-divider {
    margin: 2.7vh 0 1.5vh;
    border-top: 1px solid $light-grey;
}

.close-login {
    position: fixed;
    top: 4vh;
    right: 4vh;
    cursor: pointer;
}

#forgot-password-screen {
    position: fixed;
    right: 0;
    background: white;
    width: 50vw;
    height: 100vh;
    z-index: 1;
}

#forgot-password-details {
    height: 40vh;
    padding: 15vh 0;
    color: $medium-grey;
}

.terms {
    text-align: center;
    padding-bottom: 20px;
}

.logo-container {
    height: 15%;
}

.login-form {
    .row {
        margin: unset;

        &.phone-select {
            display: flex;
            align-items: flex-end;
        }
    }

    .input-field {
        margin-top: 1vh;
        margin-bottom: 1vh;

        .helper-text.error-helper-text {
            color: red;
            font-size: 1.3vh;
            margin-top: -1vh;
            margin-bottom: .5vh;
            min-height: unset;
        }

        input {
            height: 5vh;
            font-size: 2.2vh;
        }
    }

    .input-field .ng-select-container,
    .materialize-ngselect .ng-select-container {
        line-height: 2.2vh;
        font-size: 2.2vh;
    }

    .input-field>label {
        font-size: 2vh;
    }
}

.sign-up-text {
    line-height: 2vh;
}

.terms a {
    cursor: pointer;
}

#disable-mobile {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: white;
    z-index: 2;
    text-align: center;
    padding: 0 10%;
    color: #999;
    font-size: 1.5em;
    display: flex;
    align-items: center;
}
