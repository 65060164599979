@import 'styles/abstracts/variables';

.submit-form-button {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $blue;

    &.width-60 {
        width: 60%;
        right: 40%;
    }

    &.save-and-new-button {
        width: 40%;
    }
}

.modal-button {
    background-color: $blue;
    border-radius: 0;
    box-shadow: none;
    line-height: 18px;

    &:not(:nth-child(1)) {
        border-left: 1px solid $light-grey;
    }

    &:focus {
        background-color: $blue;
    }

    &.button-grey {
        color: $white;

        &:focus {
            background-color: $light-grey;
        }

        &.grey-fa-icon {
            color: $medium-grey;
            font-size: 1.2em;
        }

        background-color: $light-grey;
    }

    &.button-dark-grey {
        color: $white;

        &:focus {
            background-color: $dark-grey-button;
        }

        background-color: $dark-grey-button;
    }
}

.modal-button-inverted {
    background-color: white;
    border-radius: 3px;
    border: 1.5px solid $blue;
    box-shadow: none;
    color: $blue;
    text-transform: none;
    font-weight: 600;
    text-shadow: $light-grey 1px 1px 1px;
    box-shadow: $blue 0px 0px 1px 0px;

    &.disabled {
        background-color: $off-white !important;
        border: 1.5px solid $medium-grey;
        box-shadow: none;
        color: $medium-grey;
    }

    &:focus {
        background-color: white;
    }

    &:hover {
        background-color: $blue;
        color: white;
    }
}

.project-problem-modal-floating-buttons {
    position: fixed;
    overflow: visible;
    right: -100%;
    border-radius: 100%;
    height: 65px;
    width: 65px;
    padding: 10px;
    transition: transform 1s;

    &.shown {
        right: 20px;

        &.expanded {
            transform: translateX(-50vw);
            -webkit-transform: translateX(-50vw);

            &.large-modal {
                transform: translateX(-70vw);
                -webkit-transform: translateX(-70vw);
            }
        }
    }

    .grid-rows-count {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 20px;
        height: 20px;
        background: #0d5f65;
        border-radius: 10px;
        padding: 0 5px;
    }

    .new-stop-button {
        position: absolute;
        bottom: -5px;
        right: -5px;
        font-size: 1.1em;
        font-weight: 700;
        height: 27px;
        width: 27px;
        background: white;
        border: 3px solid $blue;
        border-radius: 100%;
        color: #0d5f65;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.grid-add-buttons {
    margin: 0 10px;
    background-color: white;
    color: $blue;
    text-transform: initial;
    border-radius: 10px;
    border: 1px solid $light-grey;
    box-shadow: none;
    user-select: none;

    &:hover {
        background-color: white;
        box-shadow: none;
    }

    &.export-button,
    &.print-button {
        border-radius: 100%;
        width: 38px;
        padding: 0;
        font-size: 1.1em;
    }
}

.stops-buttons-wrapper {
    position: relative;
    width: 10vw;

    .grid-dropdown-element {
        display: none;
        position: absolute;
        top: 100%;
        left: -1px;
        background: white;
        width: 100%;
        cursor: pointer;
        color: $blue;
        border: 1px solid $light-grey;
        z-index: 1;
        font-size: .8em;

        &.element3 {
            top: 300%;
        }

        &.element4 {
            top: 400%;
        }

        &.element5 {
            top: 500%;
        }
    }

    &:hover {
        border-radius: 10px 10px 0 0;

        .grid-dropdown-element {
            display: unset;
        }
    }
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    top: 200% !important;
}

.inputfile:focus+label,
.inputfile+label:hover {
    background-color: white;
}

.small-button {
    border-radius: 10px;
    background: $blue;
    color: white;
}

.round-button {
    width: 1em;
    height: 1em;
    line-height: .95em;
    background: $blue;
    color: $white;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
}

.modal-close-button {
    cursor: pointer;
}

.mily-button {
    background: none;
    color: $blue;
    border: 1px solid $blue;
    padding: 5px 20px;
    height: 35px;
    line-height: 24px;
    text-transform: none;
    font-weight: 600;
    box-shadow: none;
    margin: 5px 10px 0 0;


    &:hover {
        color: white;
        background-color: $blue;
        box-shadow: none;
    }

    &:focus,
    &:active {
        color: $blue;
        background-color: white;
    }

    &.inverse {
        background: $blue;
        color: white;
        border-color: white;

        &:hover {
            border-color: $blue;
            color: $blue;
            background-color: white;
        }

        &:focus,
        &:active {
            border-color: $blue;
            color: white;
            background-color: $blue;
        }
    }
}

#mily-footer .mily-button {
    height: 30px;
    padding: 0 .8vw;
    margin: 0;

    &.round {
        border-radius: 100%;
        width: 30px;
        padding: 0;
    }
}

.secret-white-bar-button {
    position: absolute;
    width: 10px;
    height: 65px;
    right: 0;
}

#open-xls-payments-button, .slide-from-right {
    animation: .3s ease-in-out 0s 1 slideInFromRight;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100px);
    }
    100% {
        transform: translateX(0);
    }
}
