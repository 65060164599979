.lm-pudo-widget-header-icon {
    &:before{
        content: '';
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 50% 50%;
    }
  }

  ._icon-company {
    &:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 64 64' style='enable-background:new 0 0 512 512' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M35 34h4v4h-4zm0-6h4v-4h-4zm4-14h-4v4h4zM29 34h-4v4h4zm0-20h-4v4h4zm0 10h-4v4h4zm7 33v-9h-3v9zm-10 0h-7V7a3 3 0 0 1 3-3h20a3 3 0 0 1 3 3v50h-7V47a1 1 0 0 0-1-1H27a1 1 0 0 0-1 1zm3-15h-2v2h2zm4 0h-2v2h2zm4 0h-2v2h2zm4 0h-2v2h2zm0-9a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1zm0-10a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1zm-2-13h2V8h-2zm-4 0h2V8h-2zm-2 3v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm-2-3h2V8h-2zm-4 0h2V8h-2zm-4 0h2V8h-2zm0 9a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm0 10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm0 10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm2 3h-2v2h2zm6 15v-9h-3v9zm-13 0H6V21a3 3 0 0 1 3-3h9zM9 44h2v-2H9zm7 8H9v2h7zm0-5H9v2h7zm0-5h-2v2h2zm0-5H9v2h7zm0-5H9v2h7zm0-5H9v2h7zm0-5H9v2h7zm42 35H46V18h9a3 3 0 0 1 3 3zM48 44h2v-2h-2zm7 8h-7v2h7zm0-5h-7v2h7zm0-5h-2v2h2zm0-5h-7v2h7zm0-5h-7v2h7zm0-5h-7v2h7zm0-5h-7v2h7zM4 58v2h56v-2z' data-name='97 building office' fill='%23ccc' opacity='1' data-original='%23000000'/%3E%3C/g%3E%3C/svg%3E");
    }
    &.active:before, &:hover:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 64 64' style='enable-background:new 0 0 512 512' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M35 34h4v4h-4zm0-6h4v-4h-4zm4-14h-4v4h4zM29 34h-4v4h4zm0-20h-4v4h4zm0 10h-4v4h4zm7 33v-9h-3v9zm-10 0h-7V7a3 3 0 0 1 3-3h20a3 3 0 0 1 3 3v50h-7V47a1 1 0 0 0-1-1H27a1 1 0 0 0-1 1zm3-15h-2v2h2zm4 0h-2v2h2zm4 0h-2v2h2zm4 0h-2v2h2zm0-9a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1zm0-10a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1zm-2-13h2V8h-2zm-4 0h2V8h-2zm-2 3v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm-2-3h2V8h-2zm-4 0h2V8h-2zm-4 0h2V8h-2zm0 9a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm0 10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm0 10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm2 3h-2v2h2zm6 15v-9h-3v9zm-13 0H6V21a3 3 0 0 1 3-3h9zM9 44h2v-2H9zm7 8H9v2h7zm0-5H9v2h7zm0-5h-2v2h2zm0-5H9v2h7zm0-5H9v2h7zm0-5H9v2h7zm0-5H9v2h7zm42 35H46V18h9a3 3 0 0 1 3 3zM48 44h2v-2h-2zm7 8h-7v2h7zm0-5h-7v2h7zm0-5h-2v2h2zm0-5h-7v2h7zm0-5h-7v2h7zm0-5h-7v2h7zm0-5h-7v2h7zM4 58v2h56v-2z' data-name='97 building office' fill='%2300aeba' opacity='1' data-original='%23000000'/%3E%3C/g%3E%3C/svg%3E");    
    }
}

._icon-heart {
    &:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 512.001 512.001' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M256.001 477.407c-2.59 0-5.179-.669-7.499-2.009-2.52-1.454-62.391-36.216-123.121-88.594-35.994-31.043-64.726-61.833-85.396-91.513C13.237 256.885-.214 219.943.003 185.49c.254-40.09 14.613-77.792 40.435-106.162 26.258-28.848 61.3-44.734 98.673-44.734 47.897 0 91.688 26.83 116.891 69.332 25.203-42.501 68.994-69.332 116.891-69.332 35.308 0 68.995 14.334 94.859 40.362 28.384 28.563 44.511 68.921 44.247 110.724-.218 34.393-13.921 71.279-40.728 109.632-20.734 29.665-49.426 60.441-85.279 91.475-60.508 52.373-119.949 87.134-122.45 88.588a15.008 15.008 0 0 1-7.541 2.032z' fill='%23ccc' opacity='1' data-original='%23000000'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
    &.active:before, &:hover:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 512.001 512.001' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M256.001 477.407c-2.59 0-5.179-.669-7.499-2.009-2.52-1.454-62.391-36.216-123.121-88.594-35.994-31.043-64.726-61.833-85.396-91.513C13.237 256.885-.214 219.943.003 185.49c.254-40.09 14.613-77.792 40.435-106.162 26.258-28.848 61.3-44.734 98.673-44.734 47.897 0 91.688 26.83 116.891 69.332 25.203-42.501 68.994-69.332 116.891-69.332 35.308 0 68.995 14.334 94.859 40.362 28.384 28.563 44.511 68.921 44.247 110.724-.218 34.393-13.921 71.279-40.728 109.632-20.734 29.665-49.426 60.441-85.279 91.475-60.508 52.373-119.949 87.134-122.45 88.588a15.008 15.008 0 0 1-7.541 2.032z' fill='%2300aeba' opacity='1' data-original='%23000000'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
}

._icon-check-fill {
    &:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 408.576 408.576' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M204.288 0C91.648 0 0 91.648 0 204.288s91.648 204.288 204.288 204.288 204.288-91.648 204.288-204.288S316.928 0 204.288 0zm114.176 150.528-130.56 129.536c-7.68 7.68-19.968 8.192-28.16.512L90.624 217.6c-8.192-7.68-8.704-20.48-1.536-28.672 7.68-8.192 20.48-8.704 28.672-1.024l54.784 50.176L289.28 121.344c8.192-8.192 20.992-8.192 29.184 0s8.192 20.992 0 29.184z' fill='%23ccc' opacity='1' data-original='%23000000' class=''/%3E%3C/g%3E%3C/svg%3E%0A");
    }
    &.active:before, &:hover:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 408.576 408.576' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M204.288 0C91.648 0 0 91.648 0 204.288s91.648 204.288 204.288 204.288 204.288-91.648 204.288-204.288S316.928 0 204.288 0zm114.176 150.528-130.56 129.536c-7.68 7.68-19.968 8.192-28.16.512L90.624 217.6c-8.192-7.68-8.704-20.48-1.536-28.672 7.68-8.192 20.48-8.704 28.672-1.024l54.784 50.176L289.28 121.344c8.192-8.192 20.992-8.192 29.184 0s8.192 20.992 0 29.184z' fill='%2300aeba' opacity='1' data-original='%23000000' class=''/%3E%3C/g%3E%3C/svg%3E%0A");
    }
}

._icon-map-marker {
    &:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M256 0C161.896 0 85.333 76.563 85.333 170.667c0 28.25 7.063 56.26 20.49 81.104L246.667 506.5c1.875 3.396 5.448 5.5 9.333 5.5s7.458-2.104 9.333-5.5l140.896-254.813c13.375-24.76 20.438-52.771 20.438-81.021C426.667 76.563 350.104 0 256 0zm0 256c-47.052 0-85.333-38.281-85.333-85.333S208.948 85.334 256 85.334s85.333 38.281 85.333 85.333S303.052 256 256 256z' fill='%23cccccc' opacity='1' data-original='%23000000' class=''/%3E%3C/g%3E%3C/svg%3E%0A");
    }
    &.active:before, &:hover:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M256 0C161.896 0 85.333 76.563 85.333 170.667c0 28.25 7.063 56.26 20.49 81.104L246.667 506.5c1.875 3.396 5.448 5.5 9.333 5.5s7.458-2.104 9.333-5.5l140.896-254.813c13.375-24.76 20.438-52.771 20.438-81.021C426.667 76.563 350.104 0 256 0zm0 256c-47.052 0-85.333-38.281-85.333-85.333S208.948 85.334 256 85.334s85.333 38.281 85.333 85.333S303.052 256 256 256z' fill='%2300aeba' opacity='1' data-original='%23000000' class=''/%3E%3C/g%3E%3C/svg%3E%0A");
    }
}

.lm-pudo-topbar-icon{
    &:before{
        display: table;
        content: "";
        width: 20px;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 50% 57%;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M511.52 172.128 482.56 56.224C479.008 41.984 466.208 32 451.52 32H60.512c-14.688 0-27.488 9.984-31.072 24.224L.48 172.128C.16 173.376 0 174.688 0 176c0 44.096 34.08 80 76 80 24.352 0 46.08-12.128 60-30.944C149.92 243.872 171.648 256 196 256s46.08-12.128 60-30.944C269.92 243.872 291.616 256 316 256s46.08-12.128 60-30.944C389.92 243.872 411.616 256 436 256c41.92 0 76-35.904 76-80 0-1.312-.16-2.624-.48-3.872zM436 288c-21.792 0-42.496-6.656-60-18.816-35.008 24.352-84.992 24.352-120 0-35.008 24.352-84.992 24.352-120 0C118.496 281.344 97.792 288 76 288c-15.712 0-30.528-3.68-44-9.952V448c0 17.664 14.336 32 32 32h128V352h128v128h128c17.664 0 32-14.336 32-32V278.048C466.528 284.32 451.712 288 436 288z' fill='%23ccc' opacity='1' data-original='%23000000' class=''/%3E%3C/g%3E%3C/svg%3E%0A");    
    }
    

    &.active-link:before, &:hover:before{
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cpath d='M511.52 172.128 482.56 56.224C479.008 41.984 466.208 32 451.52 32H60.512c-14.688 0-27.488 9.984-31.072 24.224L.48 172.128C.16 173.376 0 174.688 0 176c0 44.096 34.08 80 76 80 24.352 0 46.08-12.128 60-30.944C149.92 243.872 171.648 256 196 256s46.08-12.128 60-30.944C269.92 243.872 291.616 256 316 256s46.08-12.128 60-30.944C389.92 243.872 411.616 256 436 256c41.92 0 76-35.904 76-80 0-1.312-.16-2.624-.48-3.872zM436 288c-21.792 0-42.496-6.656-60-18.816-35.008 24.352-84.992 24.352-120 0-35.008 24.352-84.992 24.352-120 0C118.496 281.344 97.792 288 76 288c-15.712 0-30.528-3.68-44-9.952V448c0 17.664 14.336 32 32 32h128V352h128v128h128c17.664 0 32-14.336 32-32V278.048C466.528 284.32 451.712 288 436 288z' fill='%2300aeba' opacity='1' data-original='%23000000' class=''/%3E%3C/g%3E%3C/svg%3E%0A");    
}
}



/*
<div class="lm-close-to-arrow-icon">
 <div class="line-left"></div> 
  <div class="line-mid"></div>
  <div class="line-right"></div> 
</div>
*/
.lm-close-to-arrow-icon{
    // width: 140px;
    //  height: 80px;
    //  left: 0px;
    //  position: absolute;

    width: 140px;
    height: 80px;
    left: -43px;
    position: absolute;
    transform: scale(.5);
    top: -7px;
    right: auto;
    bottom: auto;

     .line-mid{
        top: 26px;
        position: absolute;
        width: 0;
        height: 5px;
        left: 90px;
        border-radius:50px;
        background: #999;
        -webkit-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }

      .line-left{
        top: 25px;
        position: absolute;
        width: 40px;
        height: 5px;
        left: 50px;
        border-radius:50px;
        background: #999;
        transform: rotateX(40deg);
        -moz-transform: rotate(40deg);  
        -o-transform: rotate(40deg);  
        -webkit-transform: rotate(45deg);  
        -ms-transform: rotate(50deg); 
        -webkit-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }

      .line-right{
        top: 25px;
        left: 50px;
        width: 40px;
        position: absolute;
        height: 5px;
        border-radius:50px;
        background: #999;
        transform: rotateX(-40deg);
        -moz-transform: rotate(-40deg);  
        -o-transform: rotate(-40deg);  
        -webkit-transform: rotate(-45deg);  
        -ms-transform: rotate(-40deg); 
          -webkit-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }

      &.arrow{
        left: -46px;

        .line-mid{
            width: 41px;
            left: 58px;
            
          }
          .line-left{
            top: 34px;
            left: 50px;
            width: 30px;
            transform: rotateX(-20deg);
            -moz-transform: rotate(-40deg);  
            -o-transform: rotate(-40deg);  
            -webkit-transform: rotate(36deg);  
            -ms-transform: rotate(-40deg); 
          }

          .line-right{
            top: 18px;
            left: 50px;
            width: 30px;
            transform: rotateX(-40deg);
            -moz-transform: rotate(-40deg);  
            -o-transform: rotate(-40deg);  
            -webkit-transform: rotate(-36deg);  
            -ms-transform: rotate(-40deg); 
          }
          
      }

   }
   