/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 20%;
    border: 1px solid #ccc;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $blue;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $blue;
}