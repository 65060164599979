$blue: #00aeba;
$white: #fff;
$nav-black: #1d2122;
$red: #ff3f3f;
$medium-red: #ee9999;
$grey-button: #f0f0f0;
$dark-grey-button: #8a8a8a;
$light-grey: #ccc;
$medium-grey: #999;
$dark-grey: #666;
$off-white: #fafafa;
$white-gradient: linear-gradient(#fff, #eaeaea);

// z index from 214 7483643 and above to be on top of support bubble

$zIndex0: 0;
$zIndex900: 900;
$zIndex901: 901;
$zIndex999: 999;
$zIndex1000: 1000;
$zIndex1001: 1001;
$zIndex1002: 1002;
$zIndex1009: 1009;
$zIndex1010: 1010;
$zIndex1011: 1011;
$zIndex1101: 1101;
$zIndex9999: 9999;
$zIndex10000: 10000;
$zIndex100001: 100001;
$zIndex100002: 100002;
$zIndex100003: 100003;